import React from 'react';
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import CardHeader from '../../card-header';
import Img from '../../img-container';
import Input from '../../input';
import styles from '../../node-cards/engagement/engagementNodeCard.module.css';
import { useDispatch } from 'react-redux';
import { setSearch } from 'store/graph/actions';
import ReactTooltip from 'react-tooltip';
import { EngagementIcon } from 'common/icon/icon';

const EngagementCard = ({ properties, headerText }) => {
  const dispatch = useDispatch();
  const {
    name,
    imgSrc = '',
    committeeType,
    groupType,
    isContextNode,
    tedUrl,
    nodeKey,
    region,
  } = properties;

  const setAsAContext = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(
      setSearch({
        displayValue: name,
        id: nodeKey,
        metroRegion: region,
        label: 'org',
        type: 'vertex',
        value: name,
      }),
    );
  };

  return (
    <div className={styles.card} style={{ width: '360px' }}>
      <CardHeader
        value={headerText}
        icon={<EngagementIcon />}
        nodeId={nodeKey}
        name={name}
        isContextNode={isContextNode}
      />
      <div className={styles.edge_content}>
        <div className={styles.content_header}>
          <div className={styles.left_section}>
            <div className={styles.name}>
              <h2 className={styles.name_desc}>{name}</h2>
              <BsChevronRight
                data-tip
                data-for={nodeKey}
                className={styles.name_icon}
                onClick={setAsAContext}
              />
              <ReactTooltip id={nodeKey} place="top" effect="solid">
                Add as a search context
              </ReactTooltip>
            </div>
          </div>
          <div className={styles.right_section}>
            <Img src={imgSrc} size="64px" />
          </div>
        </div>
        <div className={styles.giving_prospect_status}>
          <div className={styles.split_wrapper}>
            <Input value={groupType} label="Group Type" />
          </div>
          <div className={styles.split_wrapper}></div>
        </div>
        <div className={styles.affiliation_unit}>
          <Input value={committeeType} label="Committee Type" />
        </div>
      </div>
    </div>
  );
};

EngagementCard.propTypes = {
  properties: PropTypes.object,
  moreInformation: PropTypes.object,
};

export default EngagementCard;
