import { put, call, select, takeLatest } from 'redux-saga/effects';
import {
  GET_MOST_CONNECTED,
  GET_TOP_CONNECTIONS,
  SET_PEER_VIEW,
  SET_RESET_VIEW,
  SET_SEARCH_CONTEXT,
  SET_VIEW,
} from './constants';
import { selectCancel, selectConnection } from 'store/core/selectors';
import { createQuery } from 'utils/query-helper';
import { getItems } from 'utils/graph-helper';
import {
  removeGraphItem,
  setGraphItem,
  setIsLoadingGraphItems,
  setMostConnected,
  setMostConnectedLoading,
  setSearch,
  setTopConnections,
  setTopConnectionsLoading,
} from './actions';
import { cancelSearch } from 'store/core/actions';
const topConnectionsQuery = async (connection, topConnectionsContext) => {
  if (connection != null) {
    if (topConnectionsContext && topConnectionsContext.id !== undefined) {
      const response = await connection.invoke(
        'topConnections',
        topConnectionsContext,
      );
      let items = [];
      for (const key in response) {
        const node = response[key];
        items.push({
          ...node,
          key,
        });
      }
      return items;
    }
  }
  return null;
};

const mostConnectedQuery = async (connection, context) => {
  if (connection != null) {
    if (context && context.id !== undefined) {
      const response = await connection.invoke('mostConnections', context);
      let items = [];
      for (const key in response) {
        const node = response[key];
        items.push({
          ...node,
          key,
        });
      }
      return items;
    }
  }
  return null;
};

const query = async (connection, searchContext, refresh = false) => {
  if (connection != null) {
    // custom view sets the id for region search.
    // need to undo it when not in custom view for region search
    if (searchContext.id === searchContext.metroRegion) {
      searchContext.id = '';
    }

    if (searchContext && searchContext.id !== undefined) {
      let traversals = 3;
      if (searchContext.id === '') {
        traversals = 2;
      }
      const query = createQuery([searchContext], [], traversals);
      const response = await connection.invoke('query', query, refresh);
      const items = getItems(response, searchContext.id);
      return items;
    }
  }
  return null;
};

const queryView = async (connection, view, searchContext, refresh = false) => {
  if (connection != null) {
    if (searchContext && searchContext.id !== undefined) {
      let traversals = 3;
      if (searchContext.id === '') {
        traversals = 2;
      }
      const query = createQuery([searchContext], [], traversals);
      const response = await connection.invoke(
        'queryView',
        view,
        query,
        refresh,
      );
      const items = getItems(
        response,
        searchContext.id,
        true,
        searchContext.id === searchContext.metroRegion,
      );
      return items;
    }
  }

  return null;
};

function* querySaga(action) {
  const { search, refresh } = action;
  yield put(setIsLoadingGraphItems(true));
  const connection = yield select(selectConnection);
  const { nodes, links, nodeCount } = yield call(
    query,
    connection,
    search,
    refresh,
  );
  const cancel = yield select(selectCancel);

  if (nodes != null && !cancel) {
    yield put(setGraphItem(search, nodes, links, nodeCount));
  } else {
    yield put(cancelSearch());
  }
  yield put(setIsLoadingGraphItems(false));
}

function* queryViewSaga(action) {
  const { view, context, refresh } = action;
  yield put(setIsLoadingGraphItems(true));
  const connection = yield select(selectConnection);

  const { nodes, links, nodeCount } = yield call(
    queryView,
    connection,
    view,
    context,
    refresh,
  );
  if (nodes != null) {
    yield put(removeGraphItem(context.id));
    yield put(
      setGraphItem({ ...context, currentView: view }, nodes, links, nodeCount),
    );
  } else {
  }
  yield put(setIsLoadingGraphItems(false));
}

const peerViewQuery = async (connection, lookIds, context) => {
  if (connection != null) {
    if (lookIds && lookIds.length > 0) {
      const response = await connection.invoke('peerTopeerList', lookIds);
      const items = getItems(response, context.id);
      return items;
    }
  }
  return null;
};

function* peerViewQuerySaga({ lookIds }) {
  yield put(setIsLoadingGraphItems(true));
  const connection = yield select(selectConnection);
  const id = `p2p_${new Date().getTime()}`;
  const context = {
    id,
    displayValue: id,
    value: id,
  };
  const { nodes, links, nodeCount } = yield call(
    peerViewQuery,
    connection,
    lookIds,
    context,
  );
  const cancel = yield select(selectCancel);

  if (nodes != null && !cancel) {
    yield put(setGraphItem(context, nodes, links, nodeCount));
  } else {
    yield put(cancelSearch());
  }
  yield put(setIsLoadingGraphItems(false));
}

function* topConnectionsSaga(action) {
  const { payload } = action;
  yield put(setTopConnectionsLoading(true));
  const connection = yield select(selectConnection);
  const topConnections = yield call(topConnectionsQuery, connection, payload);
  if (topConnections?.length > 0) {
    yield put(setTopConnections(payload.id, topConnections));
  }
  yield put(setTopConnectionsLoading(false));
}

function* mostConnectedSaga(action) {
  const { payload } = action;
  yield put(setMostConnectedLoading(true));
  const connection = yield select(selectConnection);
  const mostConnected = yield call(mostConnectedQuery, connection, payload);
  if (mostConnected?.length > 0) {
    yield put(setMostConnected(payload.id, mostConnected));
  }
  yield put(setMostConnectedLoading(false));
}

function* resetViewSaga(action) {
  const { context } = action;
  const { currentView, ...contextWithoutView } = context;
  yield put(removeGraphItem(context.id));
  yield put(setSearch(contextWithoutView));
}

function* graphSaga() {
  yield takeLatest(SET_SEARCH_CONTEXT, querySaga);
  yield takeLatest(SET_VIEW, queryViewSaga);
  yield takeLatest(SET_RESET_VIEW, resetViewSaga);
  yield takeLatest(SET_PEER_VIEW, peerViewQuerySaga);
  yield takeLatest(GET_TOP_CONNECTIONS, topConnectionsSaga);
  yield takeLatest(GET_MOST_CONNECTED, mostConnectedSaga);
}

export default graphSaga;
