import React, { useState } from 'react';
import DropDown from '../../drop-down';
import styles from './prospect.module.css';
import Item from './item';
import { useSelector } from 'react-redux';
import { selectProspectStatus } from 'store/filters/selectors';

const ProspectFilter = ({
  local = undefined,
  setLocal = undefined,
  useLocal = false,
}) => {
  const [open, setOpen] = useState(false);
  const prospects = useSelector(selectProspectStatus);
  const handleToggle = () => {
    setOpen(!open);
  };

  const values = useLocal ? local : prospects;
  return (
    <div className={!open ? 'filter_by_wrapper' : 'filter_by_open_wrapper'}>
      <DropDown
        filterText="Prospect status"
        onClick={handleToggle}
        open={open}
      />
      {open && (
        <div className={styles.filter_grid}>
          {Object.keys(values).map((x) => {
            const filter = values[x];
            return (
              <Item
                key={x}
                prospectKey={x}
                value={filter.text}
                isSelected={filter.isSelected}
                useLocal={useLocal}
                setLocal={setLocal}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProspectFilter;
