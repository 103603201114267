import { call, put, select, takeLatest } from 'redux-saga/effects';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { SEND_FEEDBACK, SETUP_SIGNALR_CONNECTION } from './constants';
import { hideFeedbackModal, setConnection, setFeedBackError } from './actions';
import { selectConnection } from './selectors';
import { selectFilters } from 'store/filters/selectors';
import { setDataDate } from './connection';

const setupConnection = async (token) => {
  try {
    const conn = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_BASE_API_URL}/graph`, {
        accessTokenFactory: () => {
          return token;
        },
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    conn.serverTimeoutInMilliseconds = 120000;

    conn.on('statusNotification', (model) => {
      console.log('model', model);
    });

    conn.on('setDataFreshDate', (date) => {
      setDataDate(date);
    });

    await conn.start();
    return conn;
  } catch (e) {}
};

function* connection(action) {
  const connection = yield call(setupConnection, action.token);
  if (connection != null) {
    yield put(setConnection(connection));
  } else {
    console.log('big error. handle this');
  }
}

const sendFeedback = async (connection, request) => {
  return await connection.invoke('sendFeedback', request, null);
};

function* sendFeedbackSaga(action) {
  const connection = yield select(selectConnection);
  try {
    if (connection != null) {
      const filters = yield select(selectFilters);
      const { title, details, systemInfo } = action.payload;
      const system = JSON.stringify({
        system: systemInfo,
        filters,
      });
      const request = {
        title,
        reproSteps: details,
        systemInfo: system,
      };
      const res = yield call(sendFeedback, connection, request);
      if (res && res !== null) {
        yield put(hideFeedbackModal('success'));
      } else {
        yield put(
          setFeedBackError(
            'Oops! Something went wrong! Please try again later',
          ),
        );
      }
    }
  } catch (e) {
    yield put(
      setFeedBackError('Oops! Something went wrong! Please try again later'),
    );
  }
}

function* coreSaga() {
  yield takeLatest(SETUP_SIGNALR_CONNECTION, connection);
  yield takeLatest(SEND_FEEDBACK, sendFeedbackSaga);
}

export default coreSaga;
