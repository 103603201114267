export const SET_TOKEN = 'APP/CORE/SET_TOKEN';
export const SETUP_SIGNALR_CONNECTION = 'APP/CORE/SETUP_SIGNALR_CONNECTION';
export const SET_CONNECTION = 'APP/CORE/SET_CONNECTION';
export const SEND_FEEDBACK = 'APP/CORE/SEND_FEEDBACK';
export const FEEBACK_MODAL = 'APP/CORE/FEEDBACK_MODAL';
export const FEEDBACK_ERROR = 'APP/CORE/FEEDBACK_ERROR';

export const RESET_COMPLETE_GRAPH = 'APP/CORE/RESET_COMPLETE_GRAPH';
export const CANCEL_SEARCH = 'APP/CORE/CANCEL_SEARCH';
export const INDIVDUAL_FILTERS = 'APP/CORE/INDIVDUAL_FILTERS';
