import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from 'common/chart/loading';
import ReGraphChartWrapper from 'common/chart';
import FilterCards from 'common/edge-filter-cards';
import { selectIsLoading } from 'store/graph/selectors';
import styles from './home.module.css';
import { FeedbackModel } from 'common/feeback_modal';
import {
  selectShowFeedbackModal,
  selectShowIndividualFilters,
} from 'store/core/selectors';
import { Sidebar } from 'common/sidebar';
import ContextFiltersModal from 'common/context-filters-modal';
import SelectedDetailNode from './selectedDetailNode';
import SelectedEdge from './selectedEdge';

const Home = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const showFeedbackModal = useSelector(selectShowFeedbackModal);
  const isLoading = useSelector(selectIsLoading);
  const showIndividualFilters = useSelector(selectShowIndividualFilters);

  return (
    <div className={styles.root}>
      <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      <div
        className={`${styles.right_section} ${
          isCollapsed && styles.full_width
        }`}
      >
        <div className={styles.filter_card_wrapper}>
          <FilterCards />
        </div>
        <SelectedEdge />
        <SelectedDetailNode />
        {isLoading ? <Loading /> : <ReGraphChartWrapper />}
      </div>
      {showFeedbackModal && <FeedbackModel />}
      {showIndividualFilters && <ContextFiltersModal />}
    </div>
  );
};

export default Home;
