import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDegreesOfSeparation } from 'store/filters/actions';
import { selectDegreesOfSeparation } from 'store/filters/selectors';
import DropDown from '../../drop-down';
import styles from './degreesSeparation.module.css';

const DegreesOfSeparationFilter = ({
  setLocal = undefined,
  local = undefined,
  useLocal = false,
}) => {
  const [open, setOpen] = useState(false);
  const degrees = useSelector(selectDegreesOfSeparation);
  const dispatch = useDispatch();
  const handleToggle = () => {
    setOpen(!open);
  };

  const setValue = (value) => {
    if (useLocal) {
      setLocal(value);
    } else dispatch(setDegreesOfSeparation(value));
  };

  const handleFirst = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    setValue(1);
  };
  const handleSecond = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    setValue(2);
  };
  const handleThird = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    setValue(3);
  };

  const value = useLocal ? local : degrees;
  return (
    <div className={!open ? styles.wrapper : styles.open_wrapper}>
      <DropDown
        filterText="Degrees from Target"
        onClick={handleToggle}
        open={open}
      />
      {open && (
        <div className={styles.open_region}>
          <div
            className={
              value === 1 ? styles.first_degree_selected : styles.first_degree
            }
            onClick={handleFirst}
          >
            1
          </div>
          <div
            className={value === 2 ? styles.degree_selected : styles.degree}
            onClick={handleSecond}
          >
            2
          </div>
          <div
            className={
              value >= 3 ? styles.last_degree_selected : styles.last_degree
            }
            onClick={handleThird}
          >
            3+
          </div>
        </div>
      )}
    </div>
  );
};

export default DegreesOfSeparationFilter;
