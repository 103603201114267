import { useSelector } from 'react-redux';
import { selectSelectedEdge } from 'store/graph/selectors';
import styles from './home.module.css';
import Relationship from 'common/edge-cards';

export const SelectedEdge = () => {
  const selectedEdge = useSelector(selectSelectedEdge);

  return selectedEdge ? (
    <div className={styles.detailed_wrapper}>
      <Relationship selectedEdge={selectedEdge} />
    </div>
  ) : (
    <></>
  );
};

export default SelectedEdge;
