import React, { useCallback, useEffect, useState } from 'react';
import debounceFunction from 'common/search-input/debounce';
import SearchItemRegion from 'common/search-input/search-item-region';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { selectConnection } from 'store/core/selectors';
import { removeRegionFilter, setRegionFilter } from 'store/filters/actions';
import { selectRegionFilter } from 'store/filters/selectors';
import { formatVertexSearchResponse } from 'utils/formatters/search-formatter';
import DropDown from '../../drop-down';
import styles from './regionFilter.module.css';

const RegionFilter = ({
  useLocal = false,
  setLocal = undefined,
  setClearLocal = undefined,
  local = undefined,
}) => {
  const dispatch = useDispatch();
  const connection = useSelector(selectConnection);
  const selectedRegions = useSelector(selectRegionFilter);
  const [open, setOpen] = useState(false);
  const [region, setRegion] = useState('');
  const [regionSearch, setRegionSearch] = useState('');
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const handleToggle = () => {
    setOpen(!open);
  };

  const setRegionValue = (val) => {
    setRegionSearch(val);
  };

  useEffect(() => {
    setIsLoading(true);
    if (connection != null) {
      if (regionSearch.length > 0) {
        connection
          .invoke('filterSearch', regionSearch, 'region')
          .then((response) => {
            setItems(formatVertexSearchResponse(response));
            setIsLoading(false);
          });
      } else if (regionSearch.length === 0) {
        setItems([]);
      }
    }
  }, [connection, regionSearch]);

  const debounceDropDown = useCallback(
    debounceFunction((nextValue) => setRegionValue(nextValue), 300),
    [],
  );

  const onChange = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    const value = e.target.value;
    setRegion(value);
    if (value === '') {
      setItems([]);
    }
    debounceDropDown(value);
  };

  const handleOnSelect = (value) => {
    setItems([]);
    setRegion('');
    if (useLocal) {
      setLocal(value.metroRegion);
    } else dispatch(setRegionFilter(value.metroRegion));
  };

  const handleClear = (region) => {
    if (useLocal) {
      setClearLocal(region);
    } else dispatch(removeRegionFilter(region));
  };

  const style = region
    ? {
        borderRadius: '8px 8px 0px 0px',
        borderBottom: 'none',
      }
    : {};

  const regions = useLocal ? Object.keys(local) : Object.keys(selectedRegions);

  return (
    <div className={!open ? 'filter_by_wrapper' : 'filter_by_open_wrapper'}>
      <DropDown filterText="Region" onClick={handleToggle} open={open} />
      {open && (
        <div className={styles.filterRegion}>
          <div className={styles.input_wrapper}>
            <input
              className={styles.search_input}
              style={style}
              placeholder="Enter a region"
              value={region}
              onChange={onChange}
            />
          </div>
          {((items && items.length > 0) || region.length > 0) && (
            <div className={styles.items_wrapper}>
              {items.length > 0 &&
                items.map((item) => (
                  <SearchItemRegion
                    key={item.id}
                    onSelect={handleOnSelect}
                    item={item}
                  />
                ))}
              {items.length === 0 && region.length > 0 && !isLoading && (
                <div>No matching items</div>
              )}
              {items.length === 0 && region.length > 0 && isLoading && (
                <div>Loading</div>
              )}
            </div>
          )}
        </div>
      )}
      {regions.length > 0 && (
        <div className={styles.regions_wrapper}>
          {regions.map((x) => (
            <div className={styles.regions_item} key={x}>
              {x}
              <AiOutlineClose
                className={styles.clear_icon}
                onClick={() => handleClear(x)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RegionFilter;
