import React, { useRef, useCallback, useState, useEffect } from 'react';
import styles from './sidebar.module.css';
import ReactTooltip from 'react-tooltip';
import { Footer } from './footer';
import ContextInputs from 'common/context-input';
import SearchInput from 'common/search-input';
import content_styles from './content.module.css';
import FilterBy from 'common/filters/filter-by';
import { useSelector } from 'react-redux';
import { selectConnection } from 'store/core/selectors';
import { getDataDate } from 'store/core/connection';

export const Sidebar = ({ isCollapsed, setIsCollapsed }) => {
  const sidebarRef = useRef(null);

  const conn = useSelector(selectConnection);
  const [dataDate, setDate] = useState(undefined);
  useEffect(() => {
    if (conn) {
      setTimeout(() => {
        const date = getDataDate();
        console.log(date);
        setDate(new Date(date).toLocaleDateString());
      }, 2000);
    }
  }, [conn]);

  const arrowHoverValue = isCollapsed ? 'Open sidebar' : 'Close sidebar';
  const ArrowButton = useCallback(() => {
    return (
      <div
        className={styles.pin_icon}
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
        data-tip
        data-for="arrow-button-id"
      >
        <div
          className={`${styles.pin_icon1} ${
            isCollapsed ? styles.collapsed_p1 : styles.p1
          }`}
        ></div>
        <div
          className={`${styles.pin_icon2} ${
            isCollapsed ? styles.collapsed_p2 : styles.p2
          }`}
        ></div>
        <ReactTooltip id="arrow-button-id" place="top" effect="solid">
          {arrowHoverValue}
        </ReactTooltip>
      </div>
    );
  }, [isCollapsed]);

  // const [isOverflow, setIsOverflow] = useState(false);
  const innerComponentRef = useRef(null);
  const outerRef = useRef(null);

  // useEffect(() => {
  //   const checkOverflow = () => {
  //     const sRef = outerRef.current;
  //     const innerComponent = innerComponentRef.current;
  //     console.log(innerComponent.scrollHeight, sRef.clientHeight);
  //     if (sRef && innerComponent) {
  //       const isOverflowing = innerComponent.scrollHeight > sRef.clientHeight;
  //       setIsOverflow(isOverflowing);
  //     }
  //   };

  //   // Check for overflow on page load and when the window is resized
  //   window.addEventListener('resize', checkOverflow);
  //   checkOverflow();

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('resize', checkOverflow);
  //   };
  // }, []);

  // console.log(isOverflow);
  return (
    <div
      ref={sidebarRef}
      className={`${styles.root} ${!isCollapsed && styles.root__pinned} ${
        isCollapsed && styles.collapsed_root
      }  `}
    >
      <div
        className={`${
          isCollapsed
            ? styles.collapsed_body
            : styles.not_collapsed_body__pinned
        }`}
      >
        <ArrowButton />
        {!isCollapsed && (
          <div className={styles.sidebar_menu}>
            <div className={styles.not_collapsed_body_header}>
              <span className={styles.not_collapsed_body_header_text}>
                Foundation Tool
              </span>
            </div>
            <div className={styles.content} ref={outerRef}>
              <div
                style={{ height: '100%', width: '100%' }}
                ref={innerComponentRef}
              >
                <NavigationContent />
              </div>
            </div>
            <Footer isCollapsed={isCollapsed} dataDate={dataDate} />
          </div>
        )}
      </div>
    </div>
  );
};

export const NavigationContent = () => {
  return (
    <>
      <div className={content_styles.search_wrapper}>
        <ContextInputs />
        <SearchInput />
      </div>
      <div>
        <FilterBy />
      </div>
    </>
  );
};
