import { useDispatch, useSelector } from 'react-redux';
import styles from './contextfiltersmodal.module.css';
import { setShowIndividualFilters } from 'store/core/actions';
import { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import {
  FILTER_OUT_SELECTED_STATE,
  getInitialProspectState,
  HIGHLIGHTED_STATE,
  SELECTED_STATE,
  UNSELECTED_STATE,
} from 'store/filters/constants';
import { selectShowIndividualFilters } from 'store/core/selectors';
import { deleteContextFilters, setContextFilters } from 'store/filters/actions';
import { selectContextFilters } from 'store/filters/selectors';
import {
  charitableOptions,
  customFamilyGroupingOptions,
  educationalOptions,
  engagementOptions,
  familyOptions,
  GetEdgeOptions,
  nodeOptions,
  organizationalOptions,
  professionalOptions,
} from 'common/edge-filter-cards/options';
import NodeFiltersPanel from './node-filters';
import EdgeFiltersPanel from './edge-filters';
import { getEdgeType } from 'common/edge-filter-cards/options-card';
import { IsObjectEmpty } from 'utils/graph-helper';
import { selectContextForIndividualFilters } from 'store/graph/selectors';
import { nodeType } from 'utils/constants';
import PersonCard from 'common/node-cards/person-card';
import OrganizationCard from 'common/node-cards/organization';
import FundCard from 'common/node-cards/fund';
import EducationCard from 'common/node-cards/education';
import EngagementCard from 'common/node-cards/engagement';

export const ContextFiltersModal = () => {
  const dispatch = useDispatch();
  const [panel, setPanel] = useState(true);

  // Node Filters
  const contextNodeId = useSelector(selectShowIndividualFilters);
  const { context, node } = useSelector(
    selectContextForIndividualFilters(contextNodeId),
  );
  const contextFilters = useSelector(selectContextFilters(contextNodeId));
  const [affiliation, setAffilations] = useState(
    contextFilters?.nodeFilters?.affiliation ?? '',
  );
  const [deceased, setDeceased] = useState(
    contextFilters?.nodeFilters?.showDeceasedConnections ?? false,
  );
  const [constituency, setConstituency] = useState(
    contextFilters?.nodeFilters?.constituency ?? {},
  );
  const [regions, setRegions] = useState(contextFilters?.region ?? {});
  const [connections, setTotalConnections] = useState(
    contextFilters?.nodeFilters?.totalConnections ?? 0,
  );
  const [degrees, setDegrees] = useState(
    contextFilters?.nodeFilters?.degreeOfSeparation ?? 1,
  );
  const [nodeSizing, setNodeSizing] = useState(
    contextFilters?.nodeFilters?.nodeSizing ?? 0,
  );
  const [min, setMinLocal] = useState(
    contextFilters?.nodeFilters?.lifetimeGiving?.min ?? undefined,
  );
  const [max, setMaxLocal] = useState(
    contextFilters?.nodeFilters?.lifetimeGiving?.max ?? undefined,
  );
  const [prospectStatus, setProspectStatus] = useState(
    contextFilters?.nodeFilters?.savedProspectStatus ??
      getInitialProspectState(),
  );
  const updateLocalProspectStatus = (key, isSelected) => {
    const updatedProspectStatus = {
      ...prospectStatus,
    };
    updatedProspectStatus[key].isSelected = isSelected;
    setProspectStatus(updatedProspectStatus);
  };

  const setConstituencyValue = (value) => {
    const newConstit = { ...constituency };
    newConstit[value] = value;
    setConstituency(newConstit);
  };

  const clearConstituencyValue = (value) => {
    const newConstit = { ...constituency };
    delete newConstit[value];
    setConstituency(newConstit);
  };

  const setRegionsValue = (value) => {
    const newRegions = { ...regions };
    newRegions[value] = value;
    setRegions(newRegions);
  };
  const clearRegionValue = (value) => {
    const updated = { ...regions };
    delete updated[value];
    setRegions(updated);
  };

  // Edge Filters
  const [familyCustomOptions, setFamilyCustomOptions] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.familyCustomOptions ??
      customFamilyGroupingOptions,
  );
  const [familyAdvanced, setAdvancedFamily] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.familyAdvanced ?? false,
  );
  const resetOptions = GetEdgeOptions();

  const [family, setFamily] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.family ?? familyOptions,
  );
  const [organizational, setOrganizational] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.organizational ??
      organizationalOptions,
  );
  const [professional, setProfessional] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.professional ??
      professionalOptions,
  );
  const [charitable, setCharitable] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.charitable ??
      charitableOptions,
  );
  const [education, setEducation] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.education ??
      educationalOptions,
  );
  const [engagement, setEngagement] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.engagement ??
      engagementOptions,
  );
  const [nodeFilters, setNodeFilters] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.nodeFilters ?? nodeOptions,
  );
  const [familyTimeFilter, setFamilyTimeFilter] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.familyTimeFilter ?? {
      start: null,
      end: null,
    },
  );

  const [professionalTimeFilter, setProfessionalTimeFilter] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.professionalTimeFilter ?? {
      start: null,
      end: null,
    },
  );
  const [organizationalTimeFilter, setOrganizationalTimeFilter] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave
      ?.organizationalTimeFilter ?? {
      start: null,
      end: null,
    },
  );
  const [charitableTimeFilter, setCharitableTimeFilter] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.charitableTimeFilter ?? {
      start: null,
      end: null,
    },
  );
  const [educationTimeFilter, setEducationTimeFilter] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.educationTimeFilter ?? {
      start: null,
      end: null,
    },
  );
  const [engagementTimeFilter, setEngagementTimeFilter] = useState(
    contextFilters?.edgeFilters?.edgeFiltersToSave?.engagementTimeFilter ?? {
      start: null,
      end: null,
    },
  );

  const clearProfessionalFilters = () => {
    setProfessionalTimeFilter({ start: null, end: null });
    setProfessional(resetOptions.getProfessionalOptions());
  };

  const clearFamilyFilters = () => {
    setFamilyTimeFilter({ start: null, end: null });
    setFamilyCustomOptions(customFamilyGroupingOptions);
    setFamily(resetOptions.getFamilyOptions());
  };

  const clearOrganizationalFilters = () => {
    setOrganizationalTimeFilter({ start: null, end: null });
    setOrganizational(resetOptions.getOrganizationalOptions());
  };

  const clearCharitableFilters = () => {
    setCharitableTimeFilter({ start: null, end: null });
    setCharitable(resetOptions.getCharitableOptions());
  };

  const clearEducationFilters = () => {
    setEducationTimeFilter({ start: null, end: null });
    setEducation(resetOptions.getEducationalOptions());
  };

  const clearEngagementFilters = () => {
    setEngagementTimeFilter({ start: null, end: null });
    setEngagement(resetOptions.getEngagementOptions());
  };

  const clearNodeFilters = () => {
    setNodeFilters(resetOptions.getNodeOptions());
  };

  // submit
  const close = () => {
    dispatch(setShowIndividualFilters(undefined));
  };

  const remove = () => {
    dispatch(deleteContextFilters(contextNodeId));
    close();
  };

  const submit = () => {
    const pf = setEdgeType(professional);
    const fam = setEdgeType(family);
    const org = setEdgeType(organizational);
    const cha = setEdgeType(charitable);
    const eng = setEdgeType(engagement);
    const edu = setEdgeType(education);
    const node = setEdgeType(nodeFilters);
    const edgesFilterOut = [
      ...pf.filterOut,
      ...fam.filterOut,
      ...org.filterOut,
      ...cha.filterOut,
      ...eng.filterOut,
      ...edu.filterOut,
    ];

    let type = UNSELECTED_STATE;
    if (
      pf.filterType === SELECTED_STATE ||
      fam.filterType === SELECTED_STATE ||
      org.filterType === SELECTED_STATE ||
      eng.filterType === SELECTED_STATE ||
      edu.filterType === SELECTED_STATE ||
      cha.filterType === SELECTED_STATE
    ) {
      type = SELECTED_STATE;
    } else if (
      pf.filterType === HIGHLIGHTED_STATE ||
      fam.filterType === HIGHLIGHTED_STATE ||
      org.filterType === HIGHLIGHTED_STATE ||
      eng.filterType === HIGHLIGHTED_STATE ||
      edu.filterType === HIGHLIGHTED_STATE ||
      cha.filterType === HIGHLIGHTED_STATE
    ) {
      type = HIGHLIGHTED_STATE;
    }

    const arr = [
      ...pf.values,
      ...fam.values,
      ...org.values,
      ...edu.values,
      ...cha.values,
      ...eng.values,
    ];

    const prospectSt = Object.values(prospectStatus)
      .filter((x) => x.isSelected)
      .map((x) => {
        return x.value;
      });

    dispatch(
      setContextFilters(
        contextNodeId,
        {
          affiliation: affiliation,
          degreeOfSeparation: degrees,
          nodeSizing,
          showDeceasedConnections: deceased,
          region: regions,
          constituency,
          totalConnections: connections,
          lifetimeGiving: { min, max },
          prospectStatus: prospectSt,
          savedProspectStatus: prospectStatus,
        },
        {
          edgeFiltersToSave: {
            family,
            familyAdvanced,
            familyCustomOptions,
            familyTimeFilter: familyTimeFilter,
            professional,
            charitable,
            engagement,
            organizational,
            education,
            nodeFilters,
            professionalTimeFilter: professionalTimeFilter,
            organizationalTimeFilter: organizationalTimeFilter,
            charitableTimeFilter: charitableTimeFilter,
            educationTimeFilter: educationTimeFilter,
            engagementTimeFilter: engagementTimeFilter,
          },
          nodeFilterOut: node.filterOut,
          filtersForEdges: { edgeFilters: arr, removeEdges: edgesFilterOut },
          nodeOptions: node.values,
          nodeFilterType: node.filterType,
          nodeTypeFilterOut: node.filterOut,
          edgeFilterType: type,
          timeFilters: {
            familyOptionsTimeFilter: familyTimeFilter,
            professionalOptionsTimeFilter: professionalTimeFilter,
            organizationalOptionsTimeFilter: organizationalTimeFilter,
            charitableOptionsTimeFilter: charitableTimeFilter,
            educationOptionsTimeFilter: educationTimeFilter,
            engagementOptionsTimeFilter: engagementTimeFilter,
          },
        },
      ),
    );
    close();
  };

  const hasFilters = !IsObjectEmpty(contextFilters);

  return (
    <div className={styles.root}>
      <div className={styles.card}>
        <MdOutlineClose className={styles.close} onClick={close} />
        <div className={styles.context}>
          {node.nodeType === nodeType.Person && (
            <PersonCard properties={node.data} showForModal />
          )}
          {node.nodeType === nodeType.Organization && (
            <OrganizationCard properties={node.data} showForModal />
          )}
          {node.nodeType === nodeType.Fund && (
            <FundCard properties={node.data} showForModal />
          )}
          {node.nodeType === nodeType.Education && (
            <EducationCard education={node.data} showForModal />
          )}
          {node.nodeType === nodeType.Engagement && (
            <EngagementCard properties={node.data} showForModal />
          )}
        </div>
        <div className={styles.panel_select}>
          <div
            className={
              panel ? styles.panel_header_selected : styles.panel_header
            }
            onClick={() => setPanel(true)}
          >
            Node Filters
          </div>
          <div
            className={
              !panel ? styles.panel_header_selected : styles.panel_header
            }
            onClick={() => setPanel(false)}
          >
            Edge Filters
          </div>
        </div>
        {panel ? (
          <NodeFiltersPanel
            affiliation={affiliation}
            setAffilations={setAffilations}
            prospectStatus={prospectStatus}
            updateLocalProspectStatus={updateLocalProspectStatus}
            max={max}
            min={min}
            setMaxLocal={setMaxLocal}
            setMinLocal={setMinLocal}
            constituency={constituency}
            clearConstituencyValue={clearConstituencyValue}
            setConstituencyValue={setConstituencyValue}
            deceased={deceased}
            setDeceased={setDeceased}
            connections={connections}
            setTotalConnections={setTotalConnections}
            regions={regions}
            clearRegionValue={clearRegionValue}
            setRegionsValue={setRegionsValue}
            setNodeSizing={setNodeSizing}
            nodeSizing={nodeSizing}
            degrees={degrees}
            setDegrees={setDegrees}
          />
        ) : (
          <EdgeFiltersPanel
            nodeFilters={nodeFilters}
            setNodeFilters={setNodeFilters}
            clearNodeFilters={clearNodeFilters}
            family={family}
            familyAdvanced={familyAdvanced}
            familyTimeFilter={familyTimeFilter}
            setFamilyTimeFilter={setFamilyTimeFilter}
            setFamily={setFamily}
            setFamilyCustomOptions={setFamilyCustomOptions}
            clearFamilyFilters={clearFamilyFilters}
            familyCustomOptions={familyCustomOptions}
            setAdvancedFamily={setAdvancedFamily}
            professional={professional}
            professionalTimeFilter={professionalTimeFilter}
            setProfessionalTimeFilter={setProfessionalTimeFilter}
            setProfessional={setProfessional}
            clearProfessionalFilters={clearProfessionalFilters}
            organizational={organizational}
            setOrganizational={setOrganizational}
            setOrganizationalTimeFilter={setOrganizationalTimeFilter}
            organizationalTimeFilter={organizationalTimeFilter}
            clearOrganizationalFilters={clearOrganizationalFilters}
            setCharitable={setCharitable}
            charitable={charitable}
            charitableTimeFilter={charitableTimeFilter}
            setCharitableTimeFilter={setCharitableTimeFilter}
            clearCharitableFilters={clearCharitableFilters}
            engagement={engagement}
            setEngagement={setEngagement}
            engagementTimeFilter={engagementTimeFilter}
            setEngagementTimeFilter={setEngagementTimeFilter}
            clearEngagementFilters={clearEngagementFilters}
            education={education}
            setEducation={setEducation}
            educationTimeFilter={educationTimeFilter}
            setEducationTimeFilter={setEducationTimeFilter}
            clearEducationFilters={clearEducationFilters}
          />
        )}
        <div className={styles.button_wrapper}>
          {hasFilters && (
            <button className={styles.button_outline} onClick={remove}>
              Remove
            </button>
          )}
          <button className={styles.button} onClick={submit}>
            {hasFilters ? 'Update Filters' : 'Add Filters'}
          </button>
        </div>
      </div>
    </div>
  );
};

const setEdgeType = (options) => {
  const keys = Object.keys(options);
  let filterOut = [];
  const values = keys.filter((x) => {
    const obj = options[x];
    if (obj.isSelected === FILTER_OUT_SELECTED_STATE) {
      filterOut.push(x);
    }
    return (
      obj.isSelected !== UNSELECTED_STATE &&
      obj.isSelected !== FILTER_OUT_SELECTED_STATE
    );
  });
  return { filterType: getEdgeType(values, options), filterOut, values };
};

export default ContextFiltersModal;
