import React from 'react';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import styles from './styles/base-filter-card.module.css';

const BaseFilterCard = ({
  isChecked,
  value,
  setEnabled,
  setSelected,
  selector,
  isOpen,
  color = '#0960E4',
}) => {
  const handleOpen = () => {
    // if currently not open, then set selected
    if (!isOpen) {
      setSelected(selector);
    } else {
      setSelected(null);
    }
  };

  const onClick = (e) => {
    setEnabled(!isChecked);
    e.stopPropagation();
  };

  const onChange = (e) => {
    e?.preventDefault();
  };

  const style = isChecked
    ? {
        color: color,
        borderColor: color,
        backgroundColor: color,
      }
    : {
        color: color,
        borderColor: color,
      };
  const name = isChecked ? 'checkbox-checked' : 'checkbox';

  return (
    <div
      className={styles.wrapper}
      onClick={handleOpen}
      style={{ borderColor: color }}
    >
      <label className="form-control">
        <input
          type="checkbox"
          name={name}
          checked={isChecked}
          style={style}
          onClick={onClick}
          onChange={onChange}
        />
      </label>
      <div className={styles.text}>{value}</div>
      {!isOpen && (
        <BsFillCaretDownFill style={{ color: color, fontSize: '12px' }} />
      )}
      {isOpen && (
        <BsFillCaretUpFill style={{ color: color, fontSize: '12px' }} />
      )}
    </div>
  );
};

export default BaseFilterCard;
