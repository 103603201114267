import { RESET_COMPLETE_GRAPH } from 'store/core/constants';
import {
  CLEAR_NODE_FILTERS,
  SET_CONSTITUENCY_FILTER,
  getInitialProspectState,
  REMOVE_REGION_FILTER,
  SET_AFFILIATION_FILTER,
  SET_CHARITABLE_OPTIONS_FILTER,
  SET_CHARITABLE_OPTIONS_FILTER_ENABLED,
  SET_DEGREES_OF_SEPARATION,
  SET_EDUCATION_OPTIONS_FILTER,
  SET_EDUCATION_OPTIONS_FILTER_ENABLED,
  SET_FAMILY_OPTIONS_FILTER,
  SET_FAMILY_OPTIONS_FILTER_ENABLED,
  SET_NODE_OPTIONS_FILTER,
  SET_NODE_OPTIONS_FILTER_ENABLED,
  SET_ORGANIZATIONAL_OPTIONS_FILTER,
  SET_ORGANIZATIONAL_OPTIONS_FILTER_ENABLED,
  SET_PROFESSIONAL_OPTIONS_FILTER,
  SET_PROFESSIONAL_OPTIONS_FILTER_ENABLED,
  SET_PROSPECT_STATUS_FILTER,
  SET_REGION_FILTER,
  SET_TOTAL_CONNECTIONS,
  REMOVE_CONSTITUENCY_FILTER,
  SET_VISIBLE_CONNECTIONS,
  SET_SHORTEST_PATH_NODE_ID,
  SET_ENGAGEMENT_OPTIONS_FILTER,
  SET_ENGAGEMENT_OPTIONS_FILTER_ENABLED,
  SET_VIEW_FILTERS,
  initialEdgeFilters,
  initialNodeFilters,
  SET_MAX_LIFETIME_GIVING,
  SET_MIN_LIFETIME_GIVING,
  ADD_HIDDEN_NODE,
  CLEAR_HIDDEN_NODE,
  RESET_HIDDEN_NODES,
  SET_NODE_SIZING_TYPE,
  SET_VIEW_LOADING,
  TOGGLE_SHOW_DECEASED_CONNECTIONS,
  SET_CONTEXT_FILTERS,
  DELETE_CONTEXT_FILTERS,
} from './constants';
import { getViewFilters } from 'utils/store-helper';

const initialState = {
  ...initialNodeFilters,
  ...initialEdgeFilters,
  hiddenNodes: {},
  shortestPathNodeId: '',
  loadingViewForContext: {},
  contextFilters: {}, // <contextId, filters>
  reset: 0,
};

export const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROSPECT_STATUS_FILTER: {
      const { prospectStatus } = state;
      const updatedProspectStatus = {
        ...prospectStatus,
      };
      updatedProspectStatus[action.key].isSelected = action.isSelected;
      return { ...state, prospectStatus: updatedProspectStatus };
    }
    case SET_DEGREES_OF_SEPARATION:
      return { ...state, degreesOfSeparation: action.degreesOfSeparation };
    case TOGGLE_SHOW_DECEASED_CONNECTIONS:
      return { ...state, showDeceasedConnections: action.payload };
    case SET_NODE_SIZING_TYPE:
      return { ...state, nodeSizingType: action.sizing };
    case SET_FAMILY_OPTIONS_FILTER:
      const famEdgeFilters = {
        ...state.edgesFilterOut,
        familyOptions: action.filterOut,
      };
      return {
        ...state,
        edgesFilterOut: famEdgeFilters,
        familyOptions: action.options,
        familyOptionsTimeFilter: action.timeFilter,
        familyOptionsEnabled:
          action.options.length > 0 || action.filterOut.length > 0,
        familyFilterType: action.filterType,
      };
    case SET_PROFESSIONAL_OPTIONS_FILTER:
      const prEdgeFilters = {
        ...state.edgesFilterOut,
        professionalOptions: action.filterOut,
      };
      return {
        ...state,
        edgesFilterOut: prEdgeFilters,
        professionalOptions: action.options,
        professionalOptionsTimeFilter: action.timeFilter,
        professionalOptionsEnabled:
          action.options.length > 0 || action.filterOut.length > 0,
        professionalFilterType: action.filterType,
      };
    case SET_ORGANIZATIONAL_OPTIONS_FILTER:
      const orgEdgeFilters = {
        ...state.edgesFilterOut,
        organizationalOptions: action.filterOut,
      };
      return {
        ...state,
        edgesFilterOut: orgEdgeFilters,
        organizationalOptions: action.options,
        organizationalOptionsTimeFilter: action.timeFilter,
        organizationalOptionsEnabled:
          action.options.length > 0 || action.filterOut.length > 0,
        organizationalFilterType: action.filterType,
      };
    case SET_CHARITABLE_OPTIONS_FILTER:
      const charEdgeFilters = {
        ...state.edgesFilterOut,
        charitableOptions: action.filterOut,
      };
      return {
        ...state,
        edgesFilterOut: charEdgeFilters,
        charitableOptions: action.options,
        charitableOptionsTimeFilter: action.timeFilter,
        charitableOptionsEnabled:
          action.options.length > 0 || action.filterOut.length > 0,
        charitableFilterType: action.filterType,
      };
    case SET_EDUCATION_OPTIONS_FILTER:
      const eduEdgeFilters = {
        ...state.edgesFilterOut,
        educationOptions: action.filterOut,
      };
      return {
        ...state,
        edgesFilterOut: eduEdgeFilters,
        educationOptions: action.options,
        educationOptionsTimeFilter: action.timeFilter,
        educationOptionsEnabled:
          action.options.length > 0 || action.filterOut.length > 0,
        educationFilterType: action.filterType,
      };
    case SET_ENGAGEMENT_OPTIONS_FILTER:
      const engEdgeFilters = {
        ...state.edgesFilterOut,
        engagementOptions: action.filterOut,
      };
      return {
        ...state,
        edgesFilterOut: engEdgeFilters,
        engagementOptions: action.options,
        engagementOptionsTimeFilter: action.timeFilter,
        engagementOptionsEnabled:
          action.options.length > 0 || action.filterOut.length > 0,
        engagementFilterType: action.filterType,
      };
    case SET_NODE_OPTIONS_FILTER:
      return {
        ...state,
        nodeFilterOut: action.filterOut,
        nodeFilterOptions: action.options,
        nodeFilterOptionsEnabled:
          action.options.length > 0 || action.filterOut.length > 0,
        nodeFilterType: action.filterType,
      };
    case SET_FAMILY_OPTIONS_FILTER_ENABLED:
      return { ...state, familyOptionsEnabled: action.value };
    case SET_PROFESSIONAL_OPTIONS_FILTER_ENABLED:
      return { ...state, professionalOptionsEnabled: action.value };
    case SET_ORGANIZATIONAL_OPTIONS_FILTER_ENABLED:
      return { ...state, organizationalOptionsEnabled: action.value };
    case SET_CHARITABLE_OPTIONS_FILTER_ENABLED:
      return { ...state, charitableOptionsEnabled: action.value };
    case SET_EDUCATION_OPTIONS_FILTER_ENABLED:
      return { ...state, educationOptionsEnabled: action.value };
    case SET_ENGAGEMENT_OPTIONS_FILTER_ENABLED:
      return { ...state, engagementOptionsEnabled: action.value };
    case SET_NODE_OPTIONS_FILTER_ENABLED:
      return { ...state, nodeFilterOptionsEnabled: action.value };
    case SET_SHORTEST_PATH_NODE_ID:
      return { ...state, shortestPathNodeId: action.value };
    case SET_REGION_FILTER:
      const { regions } = state;
      const newRegions = { ...regions };
      newRegions[action.value] = action.value;
      return { ...state, regions: newRegions };
    case REMOVE_REGION_FILTER:
      const updated = { ...state.regions };
      delete updated[action.value];
      return { ...state, regions: updated };
    case SET_MAX_LIFETIME_GIVING: {
      if (action.payload === undefined) {
        return {
          ...state,
          totalLifetimeGivingMax: undefined,
        };
      }
      const { totalLifetimeGivingMin } = state;
      let newMin = totalLifetimeGivingMin;
      if (totalLifetimeGivingMin && totalLifetimeGivingMin > action.payload) {
        newMin = undefined;
      }
      return {
        ...state,
        totalLifetimeGivingMax: action.payload,
        totalLifetimeGivingMin: newMin,
      };
    }
    case SET_MIN_LIFETIME_GIVING: {
      if (action.payload === undefined) {
        return {
          ...state,
          totalLifetimeGivingMin: undefined,
        };
      } else {
        const { totalLifetimeGivingMax } = state;
        let newMax = totalLifetimeGivingMax;
        if (totalLifetimeGivingMax && totalLifetimeGivingMax < action.payload) {
          newMax = undefined;
        }
        return {
          ...state,
          totalLifetimeGivingMin: action.payload,
          totalLifetimeGivingMax: newMax,
        };
      }
    }
    case SET_TOTAL_CONNECTIONS: {
      return { ...state, totalConnections: action.value };
    }
    case SET_AFFILIATION_FILTER: {
      return { ...state, affiliation: action.value };
    }
    case SET_VISIBLE_CONNECTIONS: {
      return { ...state, showVisibleConnections: action.value };
    }
    case SET_CONSTITUENCY_FILTER:
      const { constituency } = state;
      const newConstit = { ...constituency };
      newConstit[action.value] = action.value;
      return { ...state, constituency: newConstit };
    case REMOVE_CONSTITUENCY_FILTER:
      const newCon = { ...state.constituency };
      delete newCon[action.value];
      return { ...state, constituency: newCon };
    case SET_VIEW_FILTERS:
      return getViewFilters(action.moreThanOneContext, action.viewType, state);
    case RESET_COMPLETE_GRAPH:
      const status = getInitialProspectState();
      return {
        ...initialState,
        prospectStatus: status,
        reset: state.reset + 1,
        hiddenNodes: {},
      };
    case CLEAR_NODE_FILTERS:
      const prospectStatus = getInitialProspectState();
      return {
        ...state,
        ...initialNodeFilters,
        prospectStatus,
        hiddenNodes: {},
      };
    case ADD_HIDDEN_NODE:
      let updatedHiddenNodes = { ...state.hiddenNodes };
      updatedHiddenNodes[action?.node?.nodeId] = action?.node.name;
      return {
        ...state,
        hiddenNodes: updatedHiddenNodes,
      };
    case CLEAR_HIDDEN_NODE:
      let updHiddenNodes = { ...state.hiddenNodes };
      delete updHiddenNodes[action.nodeId];
      return {
        ...state,
        hiddenNodes: updHiddenNodes,
      };
    case SET_CONTEXT_FILTERS:
      const newContextFilters = { ...state.contextFilters };
      newContextFilters[action.id] = {};
      newContextFilters[action.id].nodeFilters = action.nodeFilters;
      newContextFilters[action.id].edgeFilters = action.edgeFilters;
      return {
        ...state,
        contextFilters: newContextFilters,
      };
    case DELETE_CONTEXT_FILTERS:
      const updatedContextFilters = { ...state.contextFilters };
      delete updatedContextFilters[action.id];
      return {
        ...state,
        contextFilters: updatedContextFilters,
      };
    case SET_VIEW_LOADING:
      return { ...state, loadingViewForContext: action.context };
    case RESET_HIDDEN_NODES:
      return {
        ...state,
        hiddenNodes: {},
      };
    default:
      return state;
  }
};

export default filtersReducer;
