import React from 'react';
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import CardHeader from '../../card-header';
import Img from '../../img-container';
import Input from '../../input';
import styles from './organizationNodeCard.module.css';
import ButtonSectionCard from '../../button-section-card';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from 'store/graph/actions';
import ReactTooltip from 'react-tooltip';
import { ProfessionalIcon } from 'common/icon/icon';
import { selectIsContextIdsEmpty } from 'store/graph/selectors';
import LastUpdated from 'common/last-updated';

const OrganizationCard = ({
  properties,
  moreInformation,
  showForModal = false,
}) => {
  const dispatch = useDispatch();
  const showShortestPath = useSelector(selectIsContextIdsEmpty);
  const {
    website,
    name,
    imgSrc = '',
    industry,
    organizationType = '',
    constituency = '',
    tedUrl,
    nodeKey,
    region,
    ingestionDateUtc,
    isContextNode,
  } = properties;
  const rightClick = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    moreInformation();
  };

  const setAsAContext = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(
      setSearch({
        displayValue: name,
        id: nodeKey,
        metroRegion: region,
        label: 'org',
        type: 'vertex',
        value: name,
      }),
    );
  };

  const cardRootStyle = showForModal ? styles.cardModal : styles.card;
  const cardContentStyle = showForModal
    ? {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottom: '2px solid #e2e2e2',
      }
    : {};

  return (
    <div className={cardRootStyle}>
      <CardHeader
        showShortestPath={!showShortestPath}
        value="Organization"
        icon={<ProfessionalIcon className={styles.cardHeaderIcon} />}
        nodeId={nodeKey}
        isContextNode={isContextNode}
        name={name}
        showForModal={showForModal}
      />
      <div style={cardContentStyle} className={styles.content}>
        <div className={styles.content_header}>
          <div className={styles.left_section}>
            <div className={styles.name}>
              <h2 className={styles.name_desc}>{name}</h2>
              <BsChevronRight
                data-tip
                data-for={nodeKey}
                className={styles.name_icon}
                onClick={setAsAContext}
              />
              <ReactTooltip id={nodeKey} place="top" effect="solid">
                Add as a search context
              </ReactTooltip>
            </div>
            <div className={styles.person_details_wrapper}>
              <div className={styles.person_details_title}>Website:</div>
              <div className={styles.person_details_value}>{website}</div>
            </div>
            <div className={styles.person_details_wrapper}>
              <div className={styles.person_details_title}>Region:</div>
              <div className={styles.person_details_value}>{region}</div>
            </div>
          </div>
          <div className={styles.right_section}>
            <Img src={imgSrc} size="64px" />
          </div>
        </div>
        <div className={styles.giving_prospect_status}>
          <div className={styles.split_wrapper}>
            <Input value={organizationType} label="Organization type" />
          </div>
          <div className={styles.split_wrapper}>
            <Input value={industry} label="Industry" />
          </div>
        </div>
        <div className={styles.affiliation_unit}>
          <Input value={constituency} label="Constituency type" />
        </div>
        <LastUpdated ingestionDateUtc={ingestionDateUtc} />
      </div>
      {!showForModal && (
        <ButtonSectionCard
          tedUrl={tedUrl}
          icon={<BsChevronRight className={styles.button_right_icon} />}
          text="More Information"
          rightClick={rightClick}
        />
      )}
    </div>
  );
};

OrganizationCard.propTypes = {
  total_giving: PropTypes.string,
  affiliation_unit: PropTypes.string,
  prospect_status: PropTypes.object,
};

export default OrganizationCard;
