export const formatVertexSearchResponse = (response) => {
  const vertexs = [];
  response.tables.forEach((table) => {
    const { columns, rows } = table;
    rows.forEach((row) => {
      let vertex = {};
      for (let i = 0; i < row.length; i++) {
        let key = columns[i].name;
        key = key.substr(0, key.indexOf('_'));
        vertex[key] = row[i];
      }
      vertexs.push(vertex);
    });
  });
  return vertexs;
};
