import OrganizationDetailsCard from 'common/node-details-cards/organization';
import PersonDetailsCard from 'common/node-details-cards/person';
import { useSelector } from 'react-redux';
import { selectSelectedVertex } from 'store/graph/selectors';
import { nodeType } from 'utils/constants';
import styles from './home.module.css';

export const SelectedDetailNode = () => {
  const selectedDetailedNode = useSelector(selectSelectedVertex);
  return selectedDetailedNode ? (
    <div className={styles.detailed_wrapper}>
      {selectedDetailedNode.nodeType === nodeType.Person && (
        <PersonDetailsCard person={selectedDetailedNode} />
      )}
      {selectedDetailedNode.nodeType === nodeType.Organization && (
        <OrganizationDetailsCard properties={selectedDetailedNode} />
      )}
    </div>
  ) : (
    <></>
  );
};

export default SelectedDetailNode;
