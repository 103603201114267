export const edgeColors = {
  family: '#0960E4',
  personal: '#00A19B',
  professional: '#97B912',
  charitable: '#DEAF00',
  education: '#FF6624',
  engagement: '#D84884',
  personalRbga: 'rgba(0, 161, 155, 0.25)',
  professionalRbga: 'rgba(151, 185, 18, 0.25)',
  charitableRbga: 'rgba(222, 175, 0, 0.25)',
  educationRbga: 'rgba(255, 102, 36, 0.25)',
  engagementRbga: 'rgba(216, 72, 132, 0.25)',
  undefined: '#000',
};

export const nodeColors = {
  person: '#A7DBEC',
  organization: '#D0DF98',
  fund: '#F0DB92',
  education: '#FEBBA0',
  engagement: '#EBADC7',
  contextNode: '#EC4863',
};

export const contextNodeColors = {
  Lavender: {
    hex: '#E6E6FA',
    lighter: '#F4F4FD',
    darker: '#C8C8E0',
  },
  Amethyst: {
    hex: '#9966CC',
    lighter: '#B38EDF',
    darker: '#7A4FA3',
  },
  Orchid: {
    hex: '#DA70D6',
    lighter: '#E89AE3',
    darker: '#B056AB',
  },
  Violet: {
    hex: '#8A2BE2',
    lighter: '#A55BE8',
    darker: '#701FB3',
  },
  Plum: {
    hex: '#DDA0DD',
    lighter: '#EAB8EA',
    darker: '#B380B3',
  },
  Grape: {
    hex: '#6A0DAD',
    lighter: '#8F4DC3',
    darker: '#540986',
  },
  Mulberry: {
    hex: '#C54B8C',
    lighter: '#DB7BAD',
    darker: '#9F3B70',
  },
  RoyalPurple: {
    hex: '#7851A9',
    lighter: '#9573BE',
    darker: '#5F4085',
  },
  DeepPurple: {
    hex: '#301934',
    lighter: '#5A3D5B',
    darker: '#241027',
  },
};

export const baseNodeColor = '#ceced3';
export const baseEdgeColor = '#ceced3';
export const nodeTextColor = '#303030';

export const nodeType = {
  Person: 'Person',
  Organization: 'Organization',
  Fund: 'Fund',
  Education: 'Education',
  Engagement: 'Engagement',
};

export const totalConnectionsFilterMax = 1000;

export const SizingTypes = {
  1: 'Most Connected',
  2: 'Largest Donor',
  3: 'Highest Capacity',
};
