import React from 'react';
import PropTypes from 'prop-types';
import { BsThreeDots, BsFillFilterCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { FaTrash } from 'react-icons/fa';
import { removeGraphItem, setResetView, setView } from 'store/graph/actions';
import { deleteContextFilters, setViewLoading } from 'store/filters/actions';
import styles from './context-input.module.css';
import useComponentVisible from 'utils/hooks';
import ReactTooltip from 'react-tooltip';
import { setViewFilters } from 'store/filters/actions';
import { setShowIndividualFilters } from 'store/core/actions';
import { selectContextFilters } from 'store/filters/selectors';
import { IsObjectEmpty } from 'utils/graph-helper';

const ContextInputItem = ({ context, contextLength }) => {
  const dispatch = useDispatch();
  const contextFilters = useSelector(selectContextFilters(context.id));
  const { ref, isVisible, setIsVisible } = useComponentVisible(false);

  let value = context.value;
  const view = context.currentView;
  const label = context.label;

  let employersViewName = 'LargestEmployers';
  let largestFunds = 'TopPhilanthropicInterests';
  let largestEngagement = 'HighestEngagement';
  if (context.id === context.metroRegion) {
    employersViewName = 'TopRegion25LargestEmployers';
    largestFunds = 'TopRegion25LargestFunds';
    largestEngagement = 'TopRegion25LargestEngagement';
    value = context.metroRegion;
  }

  const removeContext = () => {
    dispatch(deleteContextFilters(context.id));
    dispatch(removeGraphItem(context.id));
    if (context.view !== undefined) {
      dispatch(setViewFilters('All'));
    }
  };
  const showViewMenu = () => {
    setIsVisible(true);
  };
  const hideViewMenu = () => {
    setIsVisible(false);
  };

  const handleAll = () => {
    dispatch(setResetView(context));
    dispatch(setViewFilters('All'));
    hideViewMenu();
  };

  const handleFilters = () => {
    dispatch(setShowIndividualFilters(context.id));
  };

  return (
    <div key={context.id}>
      <div
        className={`${styles.input_wrapper} ${
          isVisible ? styles.bottomBorder : ''
        }`}
        key={context.id}
      >
        <input
          className={styles.search_input}
          type="text"
          data-tip
          data-for={`context-id-${context.id}`}
          value={value}
          readOnly
        />
        <ReactTooltip
          id={`context-id-${context.id}`}
          place="top"
          effect="solid"
        >
          {value}
        </ReactTooltip>
        <div className={styles.node_count}>{context.nodeCount}</div>
        {!IsObjectEmpty(contextFilters) && (
          <div className={styles.remove_target} onClick={handleFilters}>
            <BsFillFilterCircleFill
              data-tip
              data-for={`${value}-filters`}
              className={styles.remove_target}
            />
            <ReactTooltip id={`${value}-filters`} place="top" effect="solid">
              Individual Filters Enabled
            </ReactTooltip>
          </div>
        )}
        <FaTrash className={styles.remove_target} onClick={removeContext} />
        <BsThreeDots onClick={showViewMenu} className={styles.icon} />
      </div>
      {isVisible && (
        <div ref={ref} className={styles.dropdownCard}>
          <div onClick={removeContext} className={styles.dropDownItem}>
            Remove Target
          </div>
          {view && (
            <div onClick={handleAll} className={styles.dropDownItem}>
              View All
            </div>
          )}
          {label === 'person' && (
            <>
              <Item
                view="Family-Interests"
                selectedView={view}
                text="View Family Interests"
                context={context}
                hideViewMenu={hideViewMenu}
                numOfContexts={contextLength}
              />
              <Item
                view="Colleagues"
                selectedView={view}
                text="View Colleagues"
                context={context}
                hideViewMenu={hideViewMenu}
                numOfContexts={contextLength}
              />
              <Item
                view="Classmates"
                selectedView={view}
                text="View Classmates"
                context={context}
                hideViewMenu={hideViewMenu}
                numOfContexts={contextLength}
              />
              {/* <Item
                view="Advancement-Connections"
                selectedView={view}
                text="View Advancement Connections"
                context={context}
                hideViewMenu={hideViewMenu}
              /> */}
            </>
          )}
          {label !== 'fund' && (
            <Item
              view={largestFunds}
              selectedView={view}
              text="View Top 25 Funds/Charitable Organizations View"
              context={context}
              hideViewMenu={hideViewMenu}
              numOfContexts={contextLength}
            />
          )}
          {label !== 'organization' && (
            <Item
              view={employersViewName}
              selectedView={view}
              text="View Top 25 Employers View"
              context={context}
              hideViewMenu={hideViewMenu}
              numOfContexts={contextLength}
            />
          )}
          {label !== 'engagement' && (
            <Item
              view={largestEngagement}
              selectedView={view}
              text="View Top 25 Engagements View"
              context={context}
              hideViewMenu={hideViewMenu}
              numOfContexts={contextLength}
            />
          )}
          {/* {label === 'education' && <Item
            view="Education-Context"
            text="View Education Context"
            selectedView={view}
            context={context}
            hideViewMenu={hideViewMenu}
          />} */}
        </div>
      )}
      <div className={styles.vertical_line} />
    </div>
  );
};

ContextInputItem.propTypes = {
  context: PropTypes.object,
};

const Item = ({
  view,
  selectedView,
  text,
  context,
  hideViewMenu,
  contextLength,
}) => {
  const dispatch = useDispatch();
  const handleView = () => {
    dispatch(setView(view, context));
    dispatch(setViewFilters(view, contextLength > 1));
    dispatch(setViewLoading(context));
    hideViewMenu();
  };

  return (
    <div
      onClick={handleView}
      className={`${styles.dropDownItem} ${
        view === selectedView ? styles.dropDownItemSelected : ''
      }`}
      data-tip
      data-for="context-view"
    >
      {text}
      <ReactTooltip id="context-view" place="top" effect="solid">
        this action will reset your current filters
      </ReactTooltip>
    </div>
  );
};

export default ContextInputItem;
