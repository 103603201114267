import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNodeSizingType } from 'store/filters/actions';
import { selectNodeSizingType } from 'store/filters/selectors';
import DropDown from '../../drop-down';
import styles from './nodeSizing.module.css';

const NodeSizing = ({
  useLocal = false,
  setLocal = undefined,
  local = undefined,
}) => {
  const [open, setOpen] = useState(false);
  const sizing = useSelector(selectNodeSizingType);
  const dispatch = useDispatch();
  const handleToggle = () => {
    setOpen(!open);
  };

  const setValue = (value) => {
    if (useLocal) {
      setLocal(value);
    } else dispatch(setNodeSizingType(value));
  };

  const handleFirst = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    setValue(0);
  };
  const handleSecond = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    setValue(1);
  };
  const handleThird = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    setValue(2);
  };

  const value = useLocal ? local : sizing;
  return (
    <div className={!open ? styles.wrapper : styles.open_wrapper}>
      <DropDown filterText="Size Nodes by" onClick={handleToggle} open={open} />
      {open && (
        <div className={styles.open_region}>
          <div
            className={
              value === 0 ? styles.first_degree_selected : styles.first_degree
            }
            onClick={handleFirst}
          >
            Most Connected
          </div>
          <div
            className={value === 1 ? styles.degree_selected : styles.degree}
            onClick={handleSecond}
          >
            Highest Capacity
          </div>
          <div
            className={
              value >= 2 ? styles.last_degree_selected : styles.last_degree
            }
            onClick={handleThird}
          >
            Largest Donors
          </div>
        </div>
      )}
    </div>
  );
};

export default NodeSizing;
