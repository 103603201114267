export const SET_GRAPH_ITEMS = 'APP/GRAPH/SET_GRAPH_ITEMS';
export const REMOVE_GRAPH_ITEMS = 'APP/GRAPH/REMOVE_GRAPH_ITEMS';
export const SET_SELECTED_VERTEX = 'APP/GRAPH/SET_SELECTED_VERTEX';
export const SET_SELECTED_LINK = 'APP/GRAPH/SET_SELECTED_LINK';
export const SET_IS_LOADING = 'APP/GRAPH/SET_IS_LOADING';
export const GET_TOP_CONNECTIONS = 'APP/GRAPH/GET_TOP_CONNECTIONS';
export const SET_TOP_CONNECTIONS = 'APP/GRAPH/SET_TOP_CONNECTIONS';
export const SET_TOP_CONNECTIONS_LOADING =
  'APP/GRAPH/SET_TOP_CONNECTIONS_LOADING';
export const GET_MOST_CONNECTED = 'APP/GRAPH/GET_MOST_CONNECTED';
export const SET_MOST_CONNECTED = 'APP/GRAPH/SET_MOST_CONNECTED';
export const SET_MOST_CONNECTED_LOADING =
  'APP/GRAPH/SET_MOST_CONNECTED_LOADING';

// saga actions
export const SET_SEARCH_CONTEXT = 'APP/GRAPH/SET_SEARCH_CONTEXT';
export const SET_VIEW = 'APP/GRAPH/SET_VIEW';
export const SET_RESET_VIEW = 'APP/GRAPH/SET_RESET_VIEW';
export const SET_PEER_VIEW = 'APP/GRAPH/SET_PEER_VIEW';

export const RELATIONSHIP_TYPE_FAMILY = 'Family';
export const RELATIONSHIP_TYPE_PERSONAL = 'Personal';
export const RELATIONSHIP_TYPE_CHARITABLE = 'Charitable';
export const RELATIONSHIP_TYPE_ORGANIZATIONAL = 'Professional';
export const RELATIONSHIP_TYPE_EDUCATION = 'EDUCATION';
export const RELATIONSHIP_TYPE_ENGAGEMENT = 'Engagement';
