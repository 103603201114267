import React from 'react';
import PropTypes from 'prop-types';
import { GiPathDistance } from 'react-icons/gi';
import { BsFillFilterCircleFill } from 'react-icons/bs';
import styles from './cardHeader.module.css';
import { useDispatch } from 'react-redux';
import { addHiddenNode, setShortestPathNodeId } from 'store/filters/actions';
import ReactTooltip from 'react-tooltip';
import { PersonIcon } from 'common/icon/icon';
import { setShowIndividualFilters } from 'store/core/actions';

const CardHeader = ({
  nodeId,
  value,
  hideIcon,
  icon = <PersonIcon className={styles.cardHeaderIcon} />,
  showShortestPath = false,
  isContextNode = false,
  name = '',
  showForModal = false,
}) => {
  const dispatch = useDispatch();

  const handleFilters = () => {
    dispatch(setShowIndividualFilters(nodeId));
  };

  const handleShortestPath = () => {
    dispatch(setShortestPathNodeId(nodeId));
  };
  const hideNode = () => {
    dispatch(addHiddenNode({ nodeId, name: name }));
  };
  return (
    <div
      className={styles.wrapper}
      style={showForModal ? { border: 'none' } : {}}
    >
      <div className={styles.text}>
        {!hideIcon && icon}
        <p className={styles.value}>{value}</p>
      </div>
      {!showForModal && (
        <>
          <div className={styles.hide} onClick={hideNode}>
            Hide
          </div>
          {isContextNode && (
            <div className={styles.distance_wrapper}>
              <BsFillFilterCircleFill
                data-tip
                data-for={`${value}-filters`}
                className={styles.three_dots}
                onClick={handleFilters}
              />
              <ReactTooltip id={`${value}-filters`} place="top" effect="solid">
                Add Individual Filters
              </ReactTooltip>
            </div>
          )}
          {showShortestPath && (
            <div className={styles.distance_wrapper}>
              <GiPathDistance
                data-tip
                data-for={value}
                className={styles.three_dots}
                onClick={handleShortestPath}
              />
              <ReactTooltip id={value} place="top" effect="solid">
                Show Path
              </ReactTooltip>
            </div>
          )}
        </>
      )}
    </div>
  );
};

CardHeader.propTypes = {
  value: PropTypes.string,
  nodeId: PropTypes.string.isRequired,
};

export default CardHeader;
