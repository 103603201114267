import {
  GET_MOST_CONNECTED,
  GET_TOP_CONNECTIONS,
  REMOVE_GRAPH_ITEMS,
  SET_GRAPH_ITEMS,
  SET_IS_LOADING,
  SET_MOST_CONNECTED,
  SET_MOST_CONNECTED_LOADING,
  SET_PEER_VIEW,
  SET_RESET_VIEW,
  SET_SEARCH_CONTEXT,
  SET_SELECTED_LINK,
  SET_SELECTED_VERTEX,
  SET_TOP_CONNECTIONS,
  SET_TOP_CONNECTIONS_LOADING,
  SET_VIEW,
} from './constants';

// item: [{item}]
export const setGraphItem = (context, newNodes, newLinks, nodeCount) => {
  return {
    type: SET_GRAPH_ITEMS,
    context,
    newNodes,
    newLinks,
    nodeCount,
  };
};

export const setIsLoadingGraphItems = (value) => {
  return {
    type: SET_IS_LOADING,
    value,
  };
};

export const removeGraphItem = (contextId) => {
  return {
    type: REMOVE_GRAPH_ITEMS,
    contextId,
  };
};

export const setSelectedVertex = (vertex) => {
  return {
    type: SET_SELECTED_VERTEX,
    vertex,
  };
};

export const getTopConnections = (label, id, region) => {
  return {
    type: GET_TOP_CONNECTIONS,
    payload: {
      type: 'vertex',
      label,
      id,
      metroRegion: region,
    },
  };
};

export const setTopConnectionsLoading = (value) => {
  return {
    type: SET_TOP_CONNECTIONS_LOADING,
    value,
  };
};

export const setTopConnections = (id, payload) => {
  return {
    type: SET_TOP_CONNECTIONS,
    id,
    payload,
  };
};

export const getMostConnected = (label, id, region) => {
  return {
    type: GET_MOST_CONNECTED,
    payload: {
      type: 'vertex',
      label,
      id,
      metroRegion: region,
    },
  };
};

export const setMostConnectedLoading = (value) => {
  return {
    type: SET_MOST_CONNECTED_LOADING,
    value,
  };
};

export const setMostConnected = (id, payload) => {
  return {
    type: SET_MOST_CONNECTED,
    id,
    payload,
  };
};

export const setSelectedLinkId = (linkId) => {
  return {
    type: SET_SELECTED_LINK,
    linkId,
  };
};

export const setSearch = (search, refresh = false) => {
  return {
    type: SET_SEARCH_CONTEXT,
    search,
    refresh,
  };
};

export const setView = (view, context, refresh = false) => {
  return {
    type: SET_VIEW,
    view,
    context,
    refresh,
  };
};

export const setResetView = (context) => {
  return {
    type: SET_RESET_VIEW,
    context,
  };
};

export const setPeerView = (lookIds) => {
  return {
    type: SET_PEER_VIEW,
    lookIds,
  };
};

export const setSearchContext = (payload) => {
  return {
    type: SET_SEARCH_CONTEXT,
    payload,
  };
};
