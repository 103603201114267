import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { MdArrowBackIos } from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { selectConnection } from 'store/core/selectors';
import styles from './styles/relationshipHeader.module.css';
import useComponentVisible from 'utils/hooks';
import LastUpdated from 'common/last-updated';

const RelationshipHeader = ({ goBack, icon, properties }) => {
  const {
    connection,
    connectionType,
    startdate,
    enddate,
    strengthOfRelationship,
    ingestionDateUtc,
  } = properties;
  const handleBack = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    goBack();
  };

  const start = startdate ? new Date(startdate).toLocaleDateString() : '-';
  const end = enddate ? new Date(enddate).toLocaleDateString() : '-';
  return (
    <div className={styles.card}>
      <div className={styles.top_header}>
        <div className={styles.back_wrapper} onClick={handleBack}>
          <MdArrowBackIos className={styles.arrow_back} />
          <p className={styles.back_text}>Back</p>
        </div>
        <BsThreeDotsVertical className={styles.three_dots} />
      </div>
      <div className={styles.content}>
        <div className={styles.connection_wrapper}>
          {icon}
          <div className={styles.connection_type}>
            {connectionType} connection
          </div>
        </div>
        <div data-tip data-for={`${connection}`} className={styles.connection}>
          {connection}
        </div>
        {connection && (
          <ReactTooltip id={`${connection}`} place="top" effect="solid">
            {connection}
          </ReactTooltip>
        )}
        <div className={styles.input_wrapper}>
          <Input value={start} label={'Started'} />
          <Input value={end} label={'Ended'} />
        </div>
        <div className={styles.input_wrapper}>
          <StrengthOfRelationship
            strengthOfRelationship={strengthOfRelationship}
            properties={properties}
          />
        </div>
        <LastUpdated ingestionDateUtc={ingestionDateUtc} />
      </div>
    </div>
  );
};

export const Input = ({ value, label, css }) => {
  const style = css || styles.inputwrapper;
  return (
    <div className={style}>
      <label className={styles.input_label}>{label}</label>
      <input className={styles.input} value={value} readOnly />
    </div>
  );
};

export const StrengthOfRelationship = ({
  strengthOfRelationship,
  properties,
}) => {
  const { ref, isVisible, setIsVisible } = useComponentVisible(false);
  const [value, setValue] = useState(strengthOfRelationship || 'Neutral');
  const connection = useSelector(selectConnection);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const handleOpen = () => {
    setIsVisible(true);
  };

  const update = async (newValue) => {
    setLoading(true);
    try {
      if (connection) {
        const request = {
          id: properties.id,
          label: properties.label,
          inVertexId: properties.inV,
          outVertexId: properties.outV,
          propName: 'strengthofrelationship',
          propValue: newValue,
        };
        const response = await connection.invoke(
          'updateEdgeProperty',
          request,
          null,
        );
        if (response === null) {
          setValue(newValue);
          // dispatch(updateEdge(properties.id, 'strengthofrelationship', newValue))
        }
      }
    } catch (e) {
      setError('Could not update value. Please try again later');
    }
    setLoading(false);
    setIsVisible(false);
  };

  useEffect(() => {
    if (connection) {
      const getStrengthOfRelationship = async () => {
        const request = {
          id: properties.id,
          propName: 'strengthofrelationship',
        };
        const response = await connection.invoke('getEdgeProperty', request);
        if (response) {
          setValue(response);
        }
      };

      getStrengthOfRelationship();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  todo: remove bad () =>
  return (
    <div className={styles.strengthOfRelationship}>
      <div className={styles.inputwrapperFull} onClick={handleOpen}>
        <label className={styles.input_label}>Strength Of Relationship</label>
        <div className={styles.strengthOfRelationshipWrapper}>
          <input
            className={styles.stengthofrelationshipInput}
            value={value}
            readOnly
          />
          {!isVisible && <BsCaretDownFill className={styles.arrow_down} />}
          {isVisible && <BsCaretUpFill className={styles.arrow_down} />}
        </div>
      </div>
      <div ref={ref}>
        {isVisible && !isLoading && (
          <div className={styles.dropDownCard} ref={ref}>
            <div
              className={styles.options}
              onClick={() => update('Knows Well')}
            >
              Knows Well
            </div>
            <div className={styles.options} onClick={() => update('Neutral')}>
              Neutral
            </div>
            <div
              className={styles.options}
              onClick={() => update('Does Not Know Well')}
            >
              Does Not Know Well
            </div>
          </div>
        )}
        {isLoading && (
          <div className={styles.dropDownCard} ref={ref}>
            <div
              className={styles.lds_ring}
              style={{ visibility: isLoading ? 'visible' : 'hidden' }}
            >
              <div></div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.error}>{error}</div>
    </div>
  );
};

export default RelationshipHeader;
