import React from 'react';
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import styles from './styles/item.module.css';
import Img from '../../img-container';
import { useDispatch } from 'react-redux';
import { setSearch } from 'store/graph/actions';
import ReactTooltip from 'react-tooltip';
import Placeholder from 'common/placeholder-loader';

const Item = ({
  name,
  imgSrc,
  label,
  nodeKey,
  region,
  showArrow = true,
  isLoading = false,
}) => {
  const dispatch = useDispatch();
  const setAsAContext = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(
      setSearch({
        displayValue: name,
        id: nodeKey,
        metroRegion: region,
        label,
        type: 'vertex',
        value: name,
      }),
    );
  };
  return (
    <div className={styles.item_wrapper}>
      <Img src={imgSrc} size="40px" />
      <div className={styles.content}>
        <div className={styles.name}>
          {isLoading ? (
            <div className={styles.loading_placeholder}>
              <Placeholder />
            </div>
          ) : (
            <div className={styles.value}>{name}</div>
          )}
          {showArrow && (
            <>
              <BsChevronRight
                data-tip
                data-for={nodeKey}
                className={styles.right_arrow}
                onClick={setAsAContext}
              />
              <ReactTooltip id={nodeKey} place="top" effect="solid">
                Add as a search context
              </ReactTooltip>
            </>
          )}
        </div>
        {/* <div className={styles.relationship}>{relationship}</div> */}
      </div>
    </div>
  );
};

Item.propTypes = {
  imgSrc: PropTypes.string,
  name: PropTypes.string,
  relationship: PropTypes.string,
};

export default Item;
