import debounceFunction from 'common/search-input/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTotalConnections } from 'store/filters/actions';
import { selectTotalConnections } from 'store/filters/selectors';
import { totalConnectionsFilterMax } from 'utils/constants';
import DropDown from '../../drop-down';
import SingleSlider from '../../sliders/single-slider';
import styles from './totalConnections.module.css';

const TotalConnectionsFilter = ({
  useLocal = false,
  setLocal = undefined,
  local = undefined,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const value = useSelector(selectTotalConnections);
  const [values, setValues] = useState(useLocal ? [local] : [value]);
  const [localValue, setLocalValue] = useState(useLocal ? [local] : [value]);
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSet = (values) => {
    setValues(values);
    debounceConnections(values[0]);
  };

  const dispatchValue = (newValue) => {
    if (!newValue) {
      if (useLocal) {
        setLocal(0);
      } else dispatch(setTotalConnections(0));
    } else {
      if (useLocal) {
        setLocal(newValue);
      } else dispatch(setTotalConnections(newValue));
    }
  };

  const debounceConnections = useCallback(
    debounceFunction((nextValue) => dispatchValue(nextValue), 500),
    [],
  );
  const debounceInputConnections = useCallback(
    debounceFunction((nextValue) => dispatchValue(nextValue), 500),
    [],
  );

  const handleChange = (e) => {
    debounceInputConnections(e.target.value);
    setLocalValue(e.target.value);
    setValues([e.target.value]);
  };

  useEffect(() => {
    if (useLocal) {
      setLocalValue(local);
      setValues([local]);
    } else {
      setLocalValue(value);
      setValues([value]);
    }
  }, [value, local]);

  return (
    <div className={!open ? 'filter_by_wrapper' : 'filter_by_open_wrapper'}>
      <DropDown
        filterText="Total connections"
        onClick={handleToggle}
        open={open}
      />
      {open && (
        <div className={styles.open_region}>
          <div className={styles.slider_wrapper}>
            <div className={styles.min}>0</div>
            <SingleSlider
              min={0}
              max={totalConnectionsFilterMax}
              step={1}
              values={values}
              setValues={handleSet}
            />
            <div className={styles.max}>{totalConnectionsFilterMax}+</div>
          </div>
          <input
            type="number"
            className={styles.input}
            value={localValue}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};

export default TotalConnectionsFilter;
