import styles from './contextfiltersmodal.module.css';

import OptionsCardWrapper from 'common/edge-filter-cards/options-card';
import {
  CharitableIcon,
  EducationIcon,
  EngagementIcon,
  FamilyIcon,
  PersonalIcon,
  ProfessionalIcon,
} from 'common/icon/icon';
import { edgeColors } from 'utils/constants';
import { FamilyGroupMapping } from 'common/edge-filter-cards/options';

export const EdgeFiltersPanel = ({
  nodeFilters,
  setNodeFilters,
  clearNodeFilters,
  family,
  familyAdvanced,
  familyTimeFilter,
  setFamilyTimeFilter,
  setFamily,
  setFamilyCustomOptions,
  clearFamilyFilters,
  familyCustomOptions,
  setAdvancedFamily,
  professional,
  professionalTimeFilter,
  setProfessionalTimeFilter,
  setProfessional,
  clearProfessionalFilters,
  organizational,
  setOrganizational,
  setOrganizationalTimeFilter,
  organizationalTimeFilter,
  clearOrganizationalFilters,
  setCharitable,
  charitable,
  charitableTimeFilter,
  setCharitableTimeFilter,
  clearCharitableFilters,
  engagement,
  setEngagement,
  engagementTimeFilter,
  setEngagementTimeFilter,
  clearEngagementFilters,
  education,
  setEducation,
  educationTimeFilter,
  setEducationTimeFilter,
  clearEducationFilters,
}) => {
  const keys = Object.keys(edgeColors);
  return (
    <div className={styles.panel}>
      <div className={styles.edge_option_wrapper}>
        <OptionsCardWrapper
          options={nodeFilters}
          setOptions={setNodeFilters}
          dispatchFilters={() => {}}
          close={() => {}}
          headerText="Set Entity Type Filters"
          clear={clearNodeFilters}
          color="undefined"
          local
        />
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: familyTimeFilter,
            setTimeFilter: setFamilyTimeFilter,
          }}
          options={family}
          setOptions={setFamily}
          setGroupOptions={setFamilyCustomOptions}
          dispatchFilters={() => {}}
          close={() => {}}
          icon={<FamilyIcon className={styles.family_icon} />}
          headerText="Family Options"
          clear={clearFamilyFilters}
          color={keys[0]}
          customGrouping={familyCustomOptions}
          customGroupingMapping={FamilyGroupMapping}
          showAdvanced={familyAdvanced}
          setShowAdvanced={setAdvancedFamily}
          local
        />
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: professionalTimeFilter,
            setTimeFilter: setProfessionalTimeFilter,
          }}
          options={professional}
          setOptions={setProfessional}
          dispatchFilters={() => {}}
          close={() => {}}
          icon={<PersonalIcon className={styles.professional_icon} />}
          headerText="Personal Options"
          clear={clearProfessionalFilters}
          color={keys[1]}
          local
        />
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: organizationalTimeFilter,
            setTimeFilter: setOrganizationalTimeFilter,
          }}
          options={organizational}
          setOptions={setOrganizational}
          dispatchFilters={() => {}}
          close={() => {}}
          icon={<ProfessionalIcon className={styles.organization_icon} />}
          headerText="Professional Options"
          clear={clearOrganizationalFilters}
          color={keys[2]}
          local
        />
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: charitableTimeFilter,
            setTimeFilter: setCharitableTimeFilter,
          }}
          options={charitable}
          setOptions={setCharitable}
          dispatchFilters={() => {}}
          close={() => {}}
          icon={<CharitableIcon className={styles.charitable_icon} />}
          headerText="Charitable Options"
          clear={clearCharitableFilters}
          color={keys[3]}
          local
        />
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: educationTimeFilter,
            setTimeFilter: setEducationTimeFilter,
          }}
          options={education}
          setOptions={setEducation}
          dispatchFilters={() => {}}
          close={() => {}}
          icon={<EducationIcon className={styles.education_icon} />}
          headerText="Education Options"
          clear={clearEducationFilters}
          color={keys[4]}
          local
        />
        <OptionsCardWrapper
          timeFilterWrapper={{
            timeFilter: engagementTimeFilter,
            setTimeFilter: setEngagementTimeFilter,
          }}
          options={engagement}
          setOptions={setEngagement}
          dispatchFilters={() => {}}
          close={() => {}}
          icon={<EngagementIcon />}
          headerText="Engagement Options"
          clear={clearEngagementFilters}
          color={keys[5]}
          local
        />
      </div>
    </div>
  );
};

export default EdgeFiltersPanel;
