import React from 'react';
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import CardHeader from '../../card-header';
import Input from '../../input';
import styles from './personCard.module.css';
import ButtonSectionCard from '../../button-section-card';
import Img from 'common/img-container';
import TypesBar from 'common/types-bar';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from 'store/graph/actions';
import ReactTooltip from 'react-tooltip';
import { currencyFormatter } from 'utils/formatters/currency';
import { selectIsContextIdsEmpty } from 'store/graph/selectors';
import LastUpdated from 'common/last-updated';

const PersonCard = ({ properties, moreInformation, showForModal = false }) => {
  const dispatch = useDispatch();
  const showShortestPath = useSelector(selectIsContextIdsEmpty);
  const {
    name,
    imgSrc,
    maiden,
    age,
    region,
    prospectStatus,
    affliationUnit,
    lifetimeGiving,
    alum,
    activelymanaged,
    donor,
    nodeKey,
    tedUrl,
    isdeceased,
    ingestionDateUtc,
    isContextNode,
    displayValue,
  } = properties;

  const contextNode = {
    displayValue: name,
    id: nodeKey,
    lookupId: '',
    metroRegion: region,
    label: 'person',
    type: 'vertex',
    value: displayValue,
  };

  const rightClick = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    moreInformation();
  };

  const setAsAContext = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    dispatch(setSearch(contextNode));
  };

  const formatted = currencyFormatter(lifetimeGiving);
  const cardRootStyle = showForModal ? styles.cardModal : styles.card;
  const cardContentStyle = showForModal
    ? {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        borderBottom: '2px solid #e2e2e2',
      }
    : {};
  return (
    <div className={cardRootStyle}>
      <CardHeader
        value="Person"
        nodeId={nodeKey}
        showShortestPath={!showShortestPath}
        isContextNode={isContextNode}
        name={name}
        showForModal={showForModal}
      />
      <div style={cardContentStyle} className={styles.content}>
        <div className={styles.content_header}>
          <div className={styles.left_section}>
            <div className={styles.name}>
              <h2 className={styles.name_desc}>{name}</h2>
              {!showForModal && (
                <>
                  <BsChevronRight
                    data-tip
                    data-for={nodeKey}
                    className={styles.name_icon}
                    onClick={setAsAContext}
                  />
                  <ReactTooltip id={nodeKey} place="top" effect="solid">
                    Add as a search context
                  </ReactTooltip>
                </>
              )}
            </div>
            {isdeceased && <div className={styles.deceased}>Deceased</div>}
            <div className={styles.person_details_wrapper}>
              <div className={styles.person_details_title}>Age:</div>
              <div className={styles.person_details_value}>{age}</div>
            </div>
            {!showForModal && (
              <div className={styles.person_details_wrapper}>
                <div className={styles.person_details_title}>Maiden:</div>
                <div className={styles.person_details_value}>{maiden}</div>
              </div>
            )}
            <div className={styles.person_details_wrapper}>
              <div className={styles.person_details_title}>Region:</div>
              <div className={styles.person_details_value}>{region}</div>
            </div>
          </div>
          <div className={styles.right_section}>
            <Img src={imgSrc} size="64px" />
          </div>
        </div>
        <div className={styles.types}>
          <TypesBar
            showAlumni={alum === 1}
            showManaged={activelymanaged}
            showDonor={donor === 1}
            iconColor="#38B2D9"
          />
        </div>
        <div className={styles.giving_prospect_status}>
          <div className={styles.split_wrapper}>
            <Input value={formatted} label="Lifetime giving" />
          </div>
          <div className={styles.split_wrapper}>
            <Input value={prospectStatus} label="Prospect status" />
          </div>
        </div>
        {!showForModal && (
          <div className={styles.affiliation_unit}>
            <Input value={affliationUnit} label="Top affiliation unit" />
          </div>
        )}
        <LastUpdated
          ingestionDateUtc={ingestionDateUtc}
          isContextNode={isContextNode}
          nodeKey={nodeKey}
        />
      </div>
      {!showForModal && (
        <ButtonSectionCard
          tedUrl={tedUrl}
          icon={<BsChevronRight className={styles.button_right_icon} />}
          text="More Information"
          rightClick={rightClick}
        />
      )}
    </div>
  );
};

PersonCard.propTypes = {
  total_giving: PropTypes.string,
  affiliation_unit: PropTypes.string,
  prospect_status: PropTypes.object,
};

export default PersonCard;
