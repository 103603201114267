import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken, setupConnection } from 'store/core/actions';

const SSOCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      // Set expiry to 24 hours from now (adjust as needed)
      const expires_in = Date.now() + 24 * 60 * 60 * 1000;

      // Store token using existing action
      dispatch(setToken(token, expires_in));

      // Setup SignalR connection
      dispatch(setupConnection(token));

      // Redirect to home
      navigate('/');
    } else {
      // If no token, redirect to login
      navigate('/login');
    }
  }, [dispatch, navigate, searchParams]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div className="lds-ring">
        <div></div>
      </div>
    </div>
  );
};

export default SSOCallback;
