import { HIGHLIGHTED_STATE, SELECTED_STATE } from 'store/filters/constants';
import {
  canAddNode,
  GetNodeSelectionStyles,
  getSize,
  IsObjectEmpty,
} from './graph-helper';
import {
  RELATIONSHIP_TYPE_FAMILY,
  RELATIONSHIP_TYPE_CHARITABLE,
  RELATIONSHIP_TYPE_EDUCATION,
  RELATIONSHIP_TYPE_ENGAGEMENT,
  RELATIONSHIP_TYPE_ORGANIZATIONAL,
  RELATIONSHIP_TYPE_PERSONAL,
} from 'store/graph/constants';

export const contextSpecificFilters = (
  items,
  nodes,
  links,
  hiddenNodes,
  contexts,
  contextFilters,
  nodesAlreadyStyled = {},
) => {
  for (const contextId in contextFilters) {
    const context = contexts[contextId];
    const contextFiltersForId = contextFilters[contextId];
    const { edgeFilters, removeEdges } =
      contextFiltersForId.edgeFilters.filtersForEdges;
    const nodeFiltersEnabled =
      contextFiltersForId.edgeFilters.nodeOptions.length > 0 ||
      contextFiltersForId.edgeFilters.nodeTypeFilterOut.length > 0;
    const isRegionsFilterEmpty = IsObjectEmpty(
      contextFiltersForId.nodeFilters.regions,
    );
    const constitencies = Object.keys(
      contextFiltersForId.nodeFilters.constituency,
    );
    const { nodeFilterType, nodeTypeFilterOut, nodeOptions, nodeSizingType } =
      contextFiltersForId.edgeFilters;
    const isConstituenciesEmpty = constitencies.length < 1;

    // add nodes from links
    if (context) {
      for (const index in context.linkIds) {
        const linkId = context.linkIds[index];
        const graphLink = links[linkId]?.link;
        if (
          removeEdges.length > 0 &&
          isEdgeIncluded(
            graphLink,
            removeEdges,
            contextFiltersForId.edgeFilters.timeFilters,
          )
        ) {
          return;
        }

        const [nodeOneId, nodeTwoId] = linkId.split('/p/');
        const nodeOne = nodes[nodeOneId]?.node;
        const nodeTwo = nodes[nodeTwoId]?.node;
        if (edgeFilters.length < 1) {
          // if no edge filters, show normal node graph
          const addedNodeOne = addNode(
            nodeOne,
            items,
            nodeOneId,
            nodeFiltersEnabled,
            nodeFilterType,
            nodeTypeFilterOut,
            nodeOptions,
            nodeSizingType,
            constitencies,
            isRegionsFilterEmpty,
            isConstituenciesEmpty,
            contextFiltersForId.nodeFilters,
            hiddenNodes,
          );
          const addedNodeTwo = addNode(
            nodeTwo,
            items,
            nodeTwoId,
            nodeFiltersEnabled,
            nodeFilterType,
            nodeTypeFilterOut,
            nodeOptions,
            nodeSizingType,
            constitencies,
            isRegionsFilterEmpty,
            isConstituenciesEmpty,
            contextFiltersForId.nodeFilters,
            hiddenNodes,
          );

          // add link only if both nodes added
          if (addedNodeOne && addedNodeTwo) {
            const { data, ...newLinkToAdd } = graphLink;
            items[linkId] = newLinkToAdd;
          }
        } else {
          if (
            contextFiltersForId.edgeFilters.edgeFilterType === HIGHLIGHTED_STATE
          ) {
            // filterType is selected state
            const isIncluded = isEdgeIncluded(
              graphLink,
              edgeFilters,
              contextFiltersForId.edgeFilters.timeFilters,
              true,
            );
            if (isIncluded) {
              const addedNodeOne = addNode(
                nodeOne,
                items,
                nodeOneId,
                nodeFiltersEnabled,
                nodeFilterType,
                nodeTypeFilterOut,
                nodeOptions,
                nodeSizingType,
                constitencies,
                isRegionsFilterEmpty,
                isConstituenciesEmpty,
                contextFiltersForId.nodeFilters,
                hiddenNodes,
                nodesAlreadyStyled,
                false,
                true,
              );
              const addedNodeTwo = addNode(
                nodeTwo,
                items,
                nodeTwoId,
                nodeFiltersEnabled,
                nodeFilterType,
                nodeTypeFilterOut,
                nodeOptions,
                nodeSizingType,
                constitencies,
                isRegionsFilterEmpty,
                isConstituenciesEmpty,
                contextFiltersForId.nodeFilters,
                hiddenNodes,
                nodesAlreadyStyled,
                false,
                true,
              );
              if (addedNodeOne && addedNodeTwo) {
                const { data, ...newGraphLink } = {
                  ...graphLink,
                  color: graphLink.activeColor,
                };
                items[linkId] = newGraphLink;
              }
            } else {
              const addedNodeOne = addNode(
                nodeOne,
                items,
                nodeOneId,
                nodeFiltersEnabled,
                nodeFilterType,
                nodeTypeFilterOut,
                nodeOptions,
                nodeSizingType,
                constitencies,
                isRegionsFilterEmpty,
                isConstituenciesEmpty,
                contextFiltersForId.nodeFilters,
                hiddenNodes,
              );
              const addedNodeTwo = addNode(
                nodeTwo,
                items,
                nodeTwoId,
                nodeFiltersEnabled,
                nodeFilterType,
                nodeTypeFilterOut,
                nodeOptions,
                nodeSizingType,
                constitencies,
                isRegionsFilterEmpty,
                isConstituenciesEmpty,
                contextFiltersForId.nodeFilters,
                hiddenNodes,
              );
              if (addedNodeOne && addedNodeTwo) {
                const { data, ...newGraphLink } = graphLink;
                items[linkId] = newGraphLink;
              }
            }
          } else {
            // filterType is selected state
            const isIncluded = isEdgeIncluded(
              graphLink,
              edgeFilters,
              contextFiltersForId.edgeFilters.timeFilters,
            );
            if (isIncluded) {
              const addedNodeOne = addNode(
                nodeOne,
                items,
                nodeOneId,
                nodeFiltersEnabled,
                nodeFilterType,
                nodeTypeFilterOut,
                nodeOptions,
                nodeSizingType,
                constitencies,
                isRegionsFilterEmpty,
                isConstituenciesEmpty,
                contextFiltersForId.nodeFilters,
                hiddenNodes,
                true,
              );
              const addedNodeTwo = addNode(
                nodeTwo,
                items,
                nodeTwoId,
                nodeFiltersEnabled,
                nodeFilterType,
                nodeTypeFilterOut,
                nodeOptions,
                nodeSizingType,
                constitencies,
                isRegionsFilterEmpty,
                isConstituenciesEmpty,
                contextFiltersForId.nodeFilters,
                hiddenNodes,
                true,
              );
              if (addedNodeOne && addedNodeTwo) {
                const { data, ...newGraphLink } = {
                  ...graphLink,
                  color: graphLink.activeColor,
                };
                items[linkId] = newGraphLink;
              }
            }
          }
        }
      }
    }
  }
};

export const isEdgeIncluded = (
  graphLink,
  edgeFilters,
  timefilters,
  isRemoval = false,
) => {
  const isIncluded = edgeFilters.includes(graphLink?.data?.connection);
  if (isIncluded) {
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_FAMILY) {
      const { familyOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        familyOptionsTimeFilter.start,
        familyOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_PERSONAL) {
      const { professionalOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        professionalOptionsTimeFilter.start,
        professionalOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_ORGANIZATIONAL) {
      const { organizationalOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        organizationalOptionsTimeFilter.start,
        organizationalOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_CHARITABLE) {
      const { charitableOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        charitableOptionsTimeFilter.start,
        charitableOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_EDUCATION) {
      const { educationOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        educationOptionsTimeFilter.start,
        educationOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
    if (graphLink?.data?.connectionType === RELATIONSHIP_TYPE_ENGAGEMENT) {
      const { engagementOptionsTimeFilter } = timefilters;
      return getTimeFilterIsIncluded(
        engagementOptionsTimeFilter.start,
        engagementOptionsTimeFilter.end,
        graphLink,
        isRemoval,
      );
    }
  }
  return false;
};

export const getTimeFilterIsIncluded = (start, end, graphLink, isRemoval) => {
  if (start === null && end === null) {
    return true;
  }
  if (!isRemoval) {
    if (start !== null) {
      const startdate = graphLink?.data?.startdate;
      if (startdate === undefined) {
        return false;
      }
      const graphStartDate = new Date(startdate);
      if (graphStartDate < new Date(start)) {
        return false;
      }
    }
    if (end !== null) {
      const enddate = graphLink?.data?.enddate;
      if (enddate === undefined) {
        return false;
      }
      const graphEndDate = new Date(enddate);
      if (graphEndDate > new Date(end)) {
        return false;
      }
    }
  } else {
    if (start !== null) {
      const startdate = graphLink?.data?.startdate;
      if (startdate !== undefined) {
        const graphStartDate = new Date(startdate);
        if (graphStartDate < new Date(start)) {
          return false;
        }
      }
    }
    if (end !== null) {
      const enddate = graphLink?.data?.enddate;
      if (enddate !== undefined) {
        const graphEndDate = new Date(enddate);
        if (graphEndDate > new Date(end)) {
          return false;
        }
      }
    }
  }
  // if none apply return true;
  return true;
};

export const addNode = (
  node,
  items,
  nodeId,
  nodeFiltersEnabled,
  nodeFilterType,
  nodeTypeFilterOut,
  nodeOptions,
  nodeSizingType,
  constitencies,
  isRegionsFilterEmpty,
  isConstituenciesEmpty,
  nodeFilters,
  hiddenNodes,
  nodesAlreadyStyled = {},
  edgeSelected = false,
  useAlreadyStyled = false,
) => {
  if (
    items[nodeId] !== undefined &&
    (useAlreadyStyled ? nodesAlreadyStyled[nodeId] !== undefined : true)
  ) {
    return true;
  }

  const canAdd = canAddNode(
    node,
    isRegionsFilterEmpty,
    isConstituenciesEmpty,
    constitencies,
    nodeFilters,
    hiddenNodes,
  );
  const isNodeFilteredOut = nodeTypeFilterOut.includes(node.data.nodeType);
  const isNodeIncludedInOptions = nodeOptions.includes(node.data.nodeType);
  const size = getSize(nodeSizingType, node.data);
  if (nodeFiltersEnabled) {
    if (nodeFilterType === HIGHLIGHTED_STATE && canAdd && !isNodeFilteredOut) {
      let newNode = node;
      if (isNodeIncludedInOptions) {
        const nodeOneSelectionStyles = GetNodeSelectionStyles(node);
        newNode = { ...node, ...nodeOneSelectionStyles };
      }

      const { data, ...nodeToAdd } = newNode;
      items[nodeId] = { ...nodeToAdd, size };
      if (useAlreadyStyled) {
        nodesAlreadyStyled[nodeId] = nodeId;
      }
      return true;
    } else if (nodeFilterType === SELECTED_STATE) {
      if (isNodeIncludedInOptions && canAdd) {
        const nodeOneSelectionStyles = GetNodeSelectionStyles(node);
        const { data, ...nodeToAdd } = {
          ...node,
          ...nodeOneSelectionStyles,
        };
        items[nodeId] = { ...nodeToAdd, size };
        if (useAlreadyStyled) {
          nodesAlreadyStyled[nodeId] = nodeId;
        }
        return true;
      }
    } else {
      if (canAdd && !isNodeFilteredOut) {
        const { data, ...nodeToAdd } = node;
        items[nodeId] = { ...nodeToAdd, size };
        if (useAlreadyStyled) {
          nodesAlreadyStyled[nodeId] = nodeId;
        }
        return true;
      }
    }
  } else if (canAdd) {
    if (edgeSelected) {
      const nodeOneSelectionStyles = GetNodeSelectionStyles(node);
      const { data, ...nodeToAdd } = {
        ...node,
        ...nodeOneSelectionStyles,
      };
      items[nodeId] = { ...nodeToAdd, size };
      if (useAlreadyStyled) {
        nodesAlreadyStyled[nodeId] = nodeId;
      }
      return true;
    } else {
      const { data, ...nodeToAdd } = node;
      items[nodeId] = { ...nodeToAdd, size };
      if (useAlreadyStyled) {
        nodesAlreadyStyled[nodeId] = nodeId;
      }
      return true;
    }
  }

  return false;
};
