import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsCheckCircle, BsClock } from 'react-icons/bs';
import styles from './styles/options.module.css';
import {
  FILTER_OUT_SELECTED_STATE,
  HIGHLIGHTED_STATE,
  SELECTED_STATE,
  UNSELECTED_STATE,
} from 'store/filters/constants';
import { edgeColors } from 'utils/constants';
import DOBInput from './dobinput';

/// values:
/// name: { isSelected: true or false }
const BaseOptionsCard = ({
  options,
  icon,
  headerText,
  updateOptions,
  setFilters,
  clear,
  color,
  timeFilter = undefined,
  customGrouping = undefined,
  customGroupingMapping = undefined,
  groupUpdateOptions = undefined,
  showAdvanced = undefined,
  setBaseOptions = undefined,
  setShowAdvanced = undefined,
  local = false,
}) => {
  const values = Object.keys(options);
  if (!customGrouping) {
    return (
      <div
        className={styles.wrapper}
        style={{
          borderColor: edgeColors[color],
        }}
      >
        <div className={styles.header_section}>
          <div className={styles.text_wrapper}>
            {icon}
            <div className={styles.header_text}>{headerText}</div>
          </div>
          <div className={styles.text_wrapper}>
            {timeFilter !== undefined && <TimeFilter filter={timeFilter} />}
            <div className={styles.header_text} onClick={clear}>
              Clear
            </div>
            {!local && (
              <>
                <div className={styles.header_text_clear}>|</div>
                <div className={styles.text_wrapper} onClick={setFilters}>
                  <div className={styles.header_text}>Apply</div>
                  <BsCheckCircle
                    className={styles.check_circle}
                    style={{
                      color: edgeColors[color],
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.values_wrapper}>
          {values.map((x) => {
            const obj = options[x];
            return (
              <OptionsItem
                OnSelect={updateOptions}
                value={x}
                key={x}
                isSelected={obj.isSelected}
                color={color}
              />
            );
          })}
        </div>
      </div>
    );
  }
  const custom = Object.keys(customGrouping);
  const AdvancedText = !showAdvanced ? 'Show Advanced' : 'Close Advanced';
  const toggleAdvanced = () => {
    // if show advanced, update back to group
    if (showAdvanced) {
      const newOptions = { ...options };
      custom.forEach((x) => {
        const obj = customGrouping[x];
        const keys = customGroupingMapping[x];
        keys.forEach((x) => {
          newOptions[x] = { isSelected: obj.isSelected };
        });
      });
      setBaseOptions(newOptions);
    }
    setShowAdvanced(!showAdvanced);
  };
  return (
    <div
      className={styles.wrapper}
      style={{
        borderColor: edgeColors[color],
      }}
    >
      <div className={styles.header_section}>
        <div className={styles.text_wrapper}>
          {icon}
          <div className={styles.header_text}>{headerText}</div>
        </div>
        <div className={styles.text_wrapper}>
          {timeFilter !== undefined && <TimeFilter filter={timeFilter} />}
          <div className={styles.header_text} onClick={toggleAdvanced}>
            {AdvancedText}
          </div>
          <div className={styles.header_text_clear}>|</div>
          <div className={styles.header_text} onClick={clear}>
            Clear
          </div>
          {!local && (
            <>
              <div className={styles.header_text_clear}>|</div>
              <div className={styles.text_wrapper} onClick={setFilters}>
                <div className={styles.header_text}>Apply</div>
                <BsCheckCircle
                  className={styles.check_circle}
                  style={{
                    color: edgeColors[color],
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.values_wrapper}>
        {!showAdvanced &&
          custom.map((x) => {
            const obj = customGrouping[x];
            return (
              <GroupOptionsItem
                OnSelect={groupUpdateOptions}
                mapping={customGroupingMapping}
                value={x}
                key={x}
                color={color}
                isSelected={obj.isSelected}
              />
            );
          })}
        {showAdvanced &&
          values.map((x) => {
            const obj = options[x];
            return (
              <OptionsItem
                OnSelect={updateOptions}
                value={x}
                key={x}
                isSelected={obj.isSelected}
                color={color}
              />
            );
          })}
      </div>
    </div>
  );
};

BaseOptionsCard.propType = {
  values: PropTypes.object,
  clear: PropTypes.func,
};

const getNextToggle = (isSelected, color) => {
  switch (isSelected) {
    case UNSELECTED_STATE:
      return {
        nextState: HIGHLIGHTED_STATE,
        style: styles[`item_wrapper_${color}`],
      };
    case HIGHLIGHTED_STATE:
      return {
        nextState: SELECTED_STATE,
        style: styles.item_wrapper_highlighted,
        customStyle: {
          background: edgeColors[`${color}Rbga`],
          border: `2px solid ${edgeColors[color]}`,
        },
      };
    case SELECTED_STATE:
      return {
        nextState: FILTER_OUT_SELECTED_STATE,
        style: styles.item_wrapper_selected,
        customStyle: {
          background: edgeColors[color],
          border: `2px solid ${edgeColors[color]}`,
        },
      };
    case FILTER_OUT_SELECTED_STATE:
      return {
        nextState: UNSELECTED_STATE,
        style: styles.item_wrapper_filter_out_selected,
      };
    default:
      return { nextState: UNSELECTED_STATE, style: styles.item_wrapper };
  }
};

export const OptionsItem = ({ value, isSelected, OnSelect, color }) => {
  const { style, nextState, customStyle } = getNextToggle(isSelected, color);
  const select = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    OnSelect(value, nextState);
  };
  return (
    <div className={style} style={customStyle} onClick={select}>
      {value}
    </div>
  );
};

// time filter
const TimeFilter = ({ filter }) => {
  const {
    timeFilter: { start, end },
    setTimeFilter,
  } = filter;
  const [showTime, setShowTime] = useState(false);
  const sTime = start || '';
  const eTime = end || '';

  const setSTime = (value) => {
    setTimeFilter({
      start: value,
      end,
    });
  };

  const setETime = (value) => {
    setTimeFilter({
      start,
      end: value,
    });
  };
  return (
    <div className={styles.timeFilterRoot}>
      <BsClock onClick={() => setShowTime(!showTime)} />
      {showTime && (
        <>
          <DOBInput
            value={sTime}
            inError={''}
            errorText="Invalid date"
            placeholderText="Enter Start Date"
            setValue={setSTime}
          />
          <hr style={{ width: '10px', border: '1px solid black' }} />
          <DOBInput
            value={eTime}
            inError={''}
            errorText="Invalid date"
            placeholderText="Enter End Date"
            setValue={setETime}
          />
        </>
      )}
    </div>
  );
};

export default BaseOptionsCard;

const GroupOptionsItem = ({ value, OnSelect, color, mapping, isSelected }) => {
  const { style, nextState, customStyle } = getNextToggle(isSelected, color);
  const select = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    const keys = mapping[value];
    OnSelect(keys, value, nextState);
  };
  return (
    <div className={style} style={customStyle} onClick={select}>
      {value}
    </div>
  );
};
