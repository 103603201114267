import { useDispatch, useSelector } from 'react-redux';
import styles from './lastUpdated.module.css';
import {
  deleteContextFilters,
  setViewFilters,
  setViewLoading,
} from 'store/filters/actions';
import { removeGraphItem, setSearch, setView } from 'store/graph/actions';
import {
  selectContexts,
  selectContextsByContextId,
} from 'store/graph/selectors';

const LastUpdated = ({ ingestionDateUtc, isContextNode, nodeKey }) => {
  const date = new Date(ingestionDateUtc);
  const dispatch = useDispatch();
  const context = useSelector(selectContextsByContextId(nodeKey));
  const contexts = useSelector(selectContexts);

  const refresh = () => {
    const contextToRefresh = {
      id: context.id,
      metroRegion: context.metroRegion,
      displayValue: context.displayValue,
      lookupId: context.lookupId,
      type: context.type,
      label: context.label,
      value: context.value,
    };
    if (context.currentView) {
      const view = context.currentView;
      dispatch(setViewFilters('All'));
      dispatch(setView(view, contextToRefresh, true));
      dispatch(setViewFilters(view, contexts.length > 1));
      dispatch(setViewLoading(contextToRefresh));
    } else {
      dispatch(deleteContextFilters(nodeKey));
      dispatch(removeGraphItem(nodeKey));
      dispatch(setSearch(contextToRefresh, true));
    }
  };
  return (
    <div className={styles.updated_date}>
      <strong>Last Updated:</strong> {date.toLocaleString()}
      {isContextNode && (
        <span className={styles.refresh} onClick={refresh}>
          Refresh
        </span>
      )}
    </div>
  );
};

export default LastUpdated;
