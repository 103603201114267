import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles/mostConnected.module.css';
import Item from './item';
import CardHeader from '../../card-header';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTopConnectionsLoading,
  selectTopConnections,
} from 'store/graph/selectors';
import { getTopConnections } from 'store/graph/actions';

const TopConnections = ({ label, id, region }) => {
  const isLoading = useSelector(selectTopConnectionsLoading);
  const dispatch = useDispatch();
  const topConnections = useSelector(selectTopConnections);
  useEffect(() => {
    dispatch(getTopConnections(label, id, region));
  }, [id]);

  return (
    <div className={styles.wrapper}>
      <CardHeader value="Top connections" hideIcon={true} />
      <div className={styles.content}>
        {isLoading && (
          <>
            <Item name="" imgSrc="" showArrow={false} isLoading={isLoading} />
            <Item name="" imgSrc="" showArrow={false} isLoading={isLoading} />
            <Item name="" imgSrc="" showArrow={false} isLoading={isLoading} />
            <Item name="" imgSrc="" showArrow={false} isLoading={isLoading} />
            <Item name="" imgSrc="" showArrow={false} isLoading={isLoading} />
          </>
        )}
        {!isLoading &&
          topConnections &&
          topConnections.map((e) => {
            let name = '';
            const { MetroRegion } = e.properties;
            if (e.label === 'person') {
              name = `${e.properties.firstname} ${e.properties.keyname}`;
            } else {
              name = e.properties.fullname;
            }
            return (
              <Item
                key={e.key}
                name={name}
                imgSrc=""
                label={e.label}
                nodeKey={e.key}
                region={MetroRegion}
              />
            );
          })}
      </div>
    </div>
  );
};

TopConnections.propTypes = {
  connections: PropTypes.array,
};

export default TopConnections;
