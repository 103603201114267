import React from 'react';
import { MdArrowBackIos, MdOutlineAddCircle } from 'react-icons/md';
import { BsThreeDotsVertical } from 'react-icons/bs';
// import { IoPersonCircle } from "react-icons/io5";
import ReactTooltip from 'react-tooltip';
import Input from '../../input';
import Img from '../../img-container';
import Snapshot from '../snapshot';
// import MostConnected from "../connections/most-connected";
import ButtonSectionCard from '../../button-section-card';
// import TieredConnections from "../connections/tiered-connections";
// import TopRelationships from "../connections/top-relationships";
import styles from './styles/personDetails.module.css';
import { useDispatch } from 'react-redux';
import { setSelectedVertex } from 'store/graph/actions';
import TypesBar from 'common/types-bar';
// import { currencyFormatter } from "utils/formatters/currency";
import MostConnected from '../connections/most-connected';
import { PersonIcon } from 'common/icon/icon';
import LastUpdated from 'common/last-updated';
import TopConnections from '../connections/top-connections';

const PersonDetailsCard = ({ person, moreInformation }) => {
  const dispatch = useDispatch();
  const {
    name,
    imgSrc,
    maiden,
    age,
    country,
    maritalStatus,
    gender,
    primaryAddress,
    region,
    prospectStatus,
    nickname,
    affiliations,
    constituency,
    recordKey,
    nodeKey,
    alum,
    donor,
    activelymanaged,
    snapshot,
    tedUrl,
    isdeceased,
    ingestionDateUtc,
    title,
  } = person;

  const handleClick = () => {
    moreInformation();
  };

  const handleBack = () => {
    dispatch(setSelectedVertex(null));
  };

  return (
    <div className={styles.card}>
      <div className={styles.top_header}>
        <div className={styles.back_wrapper} onClick={handleBack}>
          <MdArrowBackIos className={styles.arrow_back} />
          <p className={styles.back_text}>Back</p>
        </div>
        <BsThreeDotsVertical className={styles.three_dots} />
      </div>
      <div className={styles.header_background}>
        <div className={styles.person_header}>
          <div className={styles.left_person_header_section}>
            <div className={styles.card_header_wrapper}>
              <PersonIcon className={styles.cardHeaderIcon} />
              <p className={styles.card_header_value}>Person</p>
            </div>
            <div data-tip data-for="registerTip" className={styles.name}>
              {name}
            </div>
            <ReactTooltip id="registerTip" place="top" effect="solid">
              {name}
            </ReactTooltip>
            {isdeceased && <div className={styles.deceased}>Deceased</div>}
          </div>
          <div className={styles.right_person_header_section}>
            <Img src={imgSrc} size="64px" />
          </div>
        </div>
        <LastUpdated ingestionDateUtc={ingestionDateUtc} />
      </div>
      <div className={styles.person_tags_wrapper}>
        <TypesBar
          showAlumni={alum === 1}
          showManaged={activelymanaged}
          showDonor={donor === 1}
        />
      </div>
      <div className={styles.content_wrapper}>
        <div className={styles.content}>
          <div className={styles.two_section}>
            <div className={styles.left_two_section}>
              <Input value={maiden} label="Maiden Name" />
            </div>
            <div className={styles.right_two_section}>
              <Input value={nickname} label="Nick Name" />
            </div>
          </div>
          <div className={styles.two_margin_section}>
            <div className={styles.left_two_section}>
              <Input value={maritalStatus} label="Marital Status" />
            </div>
            <div className={styles.right_two_section}>
              <Input value={gender} label="Gender" />
            </div>
          </div>
          <div className={styles.age_country_section}>
            <div className={styles.age_section}>
              <Input value={age} label="Age" />
            </div>
            <div className={styles.country_section}>
              <Input value={country} label="Country" />
            </div>
          </div>
          <div className={styles.input_wrapper}>
            <Input value={primaryAddress} label="Primary Address" />
          </div>
          <div className={styles.input_wrapper}>
            <Input value={title} label="Job Title" />
          </div>
          <div className={styles.input_wrapper}>
            <Input value={region} label="Region" />
          </div>
          <div className={styles.input_wrapper}>
            <Input value={prospectStatus} label="Prospect Status" />
          </div>
          <div className={styles.input_wrapper}>
            <Input value={affiliations} label="Affiliations" />
          </div>
          <div className={styles.input_wrapper}>
            <Input value={constituency} label="Constituency Type" />
          </div>
        </div>
        <div className={styles.more_section}>
          <Snapshot snapshot={snapshot} />
          <div className={styles.connections_wrapper}>
            <div className={styles.connections_title}>Connections</div>
            <MostConnected label={recordKey} id={nodeKey} region={region} />
            <TopConnections label={recordKey} id={nodeKey} region={region} />
            {/* <TopRelationships connections={connections} />
              <TieredConnections connections={connections} /> */}
          </div>
        </div>
      </div>
      <ButtonSectionCard
        tedUrl={tedUrl}
        icon={<MdOutlineAddCircle className={styles.button_right_icon} />}
        handleClick={handleClick}
      />
    </div>
  );
};

export default PersonDetailsCard;
